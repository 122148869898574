<template>
  <base-modal :title="state.title" @closeModal="$emit('hideModal')">
    <template #modalBody>
      <input-basic
        class="social-input"
        :label="state.subTitle"
        placeholder="주소를 입력해주세요"
        type="url"
        :default-value="state.channelForm.url"
        :error-message="state.channelForm.error"
        @updateData="(value) => actions.updateChannelUrl(value)"
      ></input-basic>

      <div class="btn-wrapper">
        <button-basic
          class="cancel-btn"
          bg-color="#ECF1F4"
          color="#0D0D10"
          text="뒤로"
          @click="actions.openSpaceSnsModal()"
        ></button-basic>

        <button-basic
          class="save-btn"
          text="추가"
          :disabled="!state.isChannelUrl"
          @action="actions.onBtnChannelCreate()"
        ></button-basic>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { computed, reactive } from "vue";
import { useStore } from "vuex";
import swal from "@/helper/swal";
import helper from "@/helper";
import BaseModal from "../../../../components/common/BaseModal.vue";
import InputBasic from "../../../../components/console/inputs/InputBasic";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";

export default {
  name: "AddBrandChannelModal",
  components: { ButtonBasic, BaseModal, InputBasic },
  props: {
    selectedSns: {
      type: Object,
      required: true,
    },
  },
  emits: ["updateChannels", "saveComplete", "openSpaceSnsModal"],
  setup(props, { emit }) {
    const store = useStore();
    const state = reactive({
      channelForm: {
        url: "",
        error: "",
      },
      title: "SNS 주소 입력",
      subTitle: computed(() => {
        return props.selectedSns.inputField === "snsId"
          ? `${props.selectedSns.title} 아이디`
          : `${props.selectedSns.title} 주소`;
      }),
      isChannelUrl: computed(() => {
        return state.channelForm.url.trim().length > 0;
      }),
    });

    const actions = {
      updateChannelUrl: (value) => {
        state.channelForm.url = value;
        if (state.channelForm.error) {
          state.channelForm.error = "";
        }
      },
      onBtnChannelCreate: async () => {
        let url = state.channelForm.url;
        let hasHttp = url.indexOf("http://") === 0;
        let hasHttps = url.indexOf("https://") === 0;

        if (!hasHttp && !hasHttps) {
          url = `https://${url}`;
        }

        if (!helper.isValidURL(url)) {
          state.channelForm.error = "잘못된 주소입니다.";
          return;
        }

        const payload = {
          url: url.trim(),
        };

        store.dispatch("space/postSpaceChannel", payload).then((res) => {
          res.data.success
            ? swal.createCompleteToast()
            : swal.messageErrorAlert(res.data.message);
          emit("saveComplete");
        });
      },
      openSpaceSnsModal: () => {
        emit("openSpaceSnsModal");
      },
      showChannelListModal: () => {
        emit("showChannelListModal");
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>
