<template>
  <base-modal title="소셜 아이콘 추가" @closeModal="$emit('hideModal')">
    <template #modalBody>
      <input-search
        v-model="state.searchedWord"
        class="search-sns"
        placeholder="SNS 검색"
      ></input-search>
      <div class="sns-list">
        <div
          v-for="(snsItem, index) in state.filterdSnsItems"
          :key="`space-sns-${index}`"
          class="sns-item"
        >
          <brand-sns-modal-item
            :space-sns="snsItem"
            @selectSpaceSns="
              (snsItem) => {
                actions.onBtnSpaceSnsSelect(snsItem);
              }
            "
          ></brand-sns-modal-item>
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { reactive, computed } from "vue";
import BaseModal from "../../../../components/common/BaseModal.vue";
import InputSearch from "../../../../components/console/inputs/InputSearch";
import BrandSnsModalItem from "../BrandSnsModalItem/BrandSnsModalItem";

export default {
  name: "EntBrandChannelListModal",
  components: { BrandSnsModalItem, BaseModal, InputSearch },
  props: {
    constChannels: {
      type: Array,
      required: true,
    },
  },
  emits: ["openInputUrl", "hideModal"],
  setup(props, { emit }) {
    const state = reactive({
      constChannels: props.constChannels,
      searchedWord: "",
      filterdSnsItems: computed(() => {
        let searchedWord = state.searchedWord.trim();
        if (searchedWord) {
          let result = state.constChannels.filter((item) =>
            item.title.includes(searchedWord.toUpperCase())
          );
          return result;
        }
        return state.constChannels;
      }),
    });

    const actions = {
      onBtnSpaceSnsSelect: (snsItem) => {
        emit("openInputUrl", snsItem);
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>
