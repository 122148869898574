<template>
  <div class="widget-card">
    <div
      class="image"
      :class="{
        active: !item.isLocked && item.isAvailable,
      }"
    >
      <!--      todo 43 타입 이미지 나오면, 해당 코드 삭제 처리-->
      <img
        v-if="item.type !== 43 && item.type !== 49"
        :src="item.images"
        :class="{ 'banner-type': state.isBannerType }"
        :alt="item.title"
      />
      <div v-if="item.type === 43" style="width: 120px; height: 120px">
        <spna style="display: block; padding-top: 30px"> 투표 </spna>
      </div>
      <div v-if="item.type === 49" style="width: 120px; height: 120px">
        <spna style="display: block; padding-top: 30px"> Embed </spna>
      </div>
      <div
        v-if="item.isAvailable && !item.isLocked"
        class="available"
        @click="actions.createWidget(item.type)"
      ></div>
      <!--            단일 생성 위젯 -->
      <div v-if="!item.isAvailable" class="unavailable">
        <div></div>
      </div>
      <!--           잠금 위젯 -->
      <div v-if="item.isLocked" class="locked"></div>
    </div>
    <p class="title sub-title-s2">
      {{ item.title }}
    </p>
    <p class="b-text-3 text-gray-second">
      {{ item.description }}
    </p>
  </div>
</template>

<script>
import { computed, reactive } from "vue";

export default {
  name: "BrandWidgetItem",
  components: {},
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  emits: ["editWidget", "createWidget"],
  setup(props, { emit }) {
    const state = reactive({
      isBannerType: computed(() => {
        return props.item.type === 41;
      }),
    });

    const actions = {
      createWidget: (type) => {
        emit("createWidget", type);
      },
      editWidget: (data) => {
        emit("editWidget", data);
      },
    };

    return {
      state,
      actions,
    };
  },
};
</script>

<style src="./style.css" scoped></style>
