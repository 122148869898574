<template>
  <div>
    <h4>브랜드홈 메뉴 설정</h4>
    <ul class="navigations-item-list">
      <draggable
        v-model="state.navigations"
        item-key="id"
        handle=".handle"
        @change="actions.sortNavigations(state.navigations)"
      >
        <template #item="{ element }">
          <li>
            <navigations-item
              :item="element"
              :draggable="true"
              :is-sorting="state.isSorting"
              :about-page-description="space.description"
              @update-published="
                (item) => {
                  actions.updateItem(item, element.id);
                }
              "
            ></navigations-item>
          </li>
        </template>
      </draggable>
    </ul>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import draggable from "vuedraggable";
import apiConsole from "@/api";
import swal from "@/helper/swal";
import NavigationsItem from "@/pages/console/EntBrand/NavigationsItem/NavigationsItem.vue";

export default {
  name: "EntBrandNavigationsSetting",
  components: {
    NavigationsItem,
    draggable,
  },
  props: {
    space: {
      type: Object,
      required: true,
    },
  },
  emits: ["reloadIframe"],
  setup(props, { emit }) {
    const state = reactive({
      navigations: [],
      isSorting: false,
    });

    onMounted(() => {
      state.navigations = props.space.navigations;
    });

    const actions = {
      updateItem: (item, resourceId) => {
        console.log(item, resourceId);
        apiConsole
          .putSpaceNavigations(resourceId, item)
          .then((res) => {
            console.log(res);
            emit("reloadIframe");
            swal.editCompleteToast();
          })
          .catch((e) => {
            swal.errorToast(e.message);
          });
      },
      sortNavigations: (navigations) => {
        state.isSorting = true;
        let navigationsResourceIds = [];

        navigations.forEach((navigation) => {
          navigationsResourceIds.push(navigation.id);
        });

        apiConsole
          .postSpaceNavigationsResort({ resourceIds: navigationsResourceIds })
          .then(() => {
            emit("reloadIframe");
            swal.editCompleteToast();
            state.isSorting = false;
          })
          .catch((e) => {
            swal.errorToast(e.message);
            state.isSorting = false;
          });
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>
