<template>
  <base-modal
    title="수요 조사하기"
    sub-title="판매하고 싶은 프리미엄 콘텐츠를 살짝 소개해주세요. 팔로워들이 실제 관심있는지 미리 확인해볼 수 있습니다."
    @closeModal="state.loading ? '' : $emit('hideModal')"
  >
    <template #modalBody>
      <div class="input-wrapper">
        <input-basic
          label="주제"
          placeholder="어떤 이벤트를 열고 싶으신가요?"
          :required="true"
          :default-value="state.fundingForm.title"
          @updateData="
            (value) => {
              actions.updateTitle(value);
            }
          "
        ></input-basic>
        <input-text-box
          label="설명"
          placeholder="이벤트에 대한 대략적인 구상을 소개해주시면 좋아요!"
          :rows="3"
          :height-resize="false"
          :border-none="false"
          :model-value="state.fundingForm.description"
          @update:modelValue="
            (value) => {
              actions.updateDescription(value);
            }
          "
        ></input-text-box>
      </div>
      <div class="btn-wrapper">
        <button-basic
          class="cancel-btn"
          bg-color="#ECF1F4"
          color="#0D0D10"
          text="뒤로"
          @click="actions.openListModal()"
        ></button-basic>
        <button-basic
          class="save-btn"
          :disabled="!state.isTitle"
          text="수정"
          @click="actions.createWidget()"
        ></button-basic>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { computed, reactive } from "vue";
import { useStore } from "vuex";
import InputBasic from "../../../../components/console/inputs/InputBasic";
import InputTextBox from "../../../../components/console/inputs/InputTextBox";
import swal from "../../../../helper/swal";
import BaseModal from "../../../../components/common/BaseModal.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";

export default {
  name: "AddBrandFundingWidgetModal",
  components: {
    ButtonBasic,
    InputBasic,
    InputTextBox,
    BaseModal,
  },
  emits: ["createWidget", "hideModal", "openWidgetListModal"],
  setup(props, { emit }) {
    const store = useStore();
    const state = reactive({
      fundingForm: {
        type: 9,
        title: "",
        description: "",
      },
      isTitle: computed(() => {
        return state.fundingForm.title.trim().length > 0;
      }),
      loading: false,
    });

    const actions = {
      updateTitle: (data) => {
        state.fundingForm.title = data;
      },
      updateDescription: (data) => {
        state.fundingForm.description = data;
      },
      createWidget: () => {
        state.loading = true;
        let formData = new FormData();

        formData.append("type", state.fundingForm.type);
        formData.append("title", state.fundingForm.title);
        formData.append("description", state.fundingForm.description);

        store.dispatch("space/postSpaceWidget", { data: formData }).then(() => {
          swal.createCompleteToast();
          state.loading = false;
          emit("saveComplete");
        });
      },
      closeModal: () => {
        if (!state.loading) {
          emit("hideModal");
        }
      },
      openListModal: () => {
        emit("openWidgetListModal");
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>
