<template>
  <div class="navigations-item">
    <div v-show="isSorting" class="event-barrier"></div>
    <div class="info">
      <button v-if="draggable" class="handle handler-btn">
        <handler-icon fill-color="#9EA0A6"></handler-icon>
      </button>

      <p content="meta-info" class="sub-title-s2">
        {{ item.label }}
      </p>
    </div>
    <div class="btn-wrapper">
      <input-switch
        v-model="state.isShow"
        @update:model-value="actions.updatePublished()"
      ></input-switch>
    </div>
  </div>
</template>

<script>
import { computed, getCurrentInstance, onMounted, reactive } from "vue";
import HandlerIcon from "@/components/console/icons/HandlerIcon.vue";
import InputSwitch from "@/components/console/inputs/InputSwitch.vue";

export default {
  name: "NavigationsItem",
  components: {
    InputSwitch,
    HandlerIcon,
  },
  props: {
    item: {
      type: Object,
      required: false,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    isSorting: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["updatePublished"],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();

    const state = reactive({
      isShow: props.item.isShow,
      saveLoading: false,
    });

    onMounted(() => {
      state.isShow = props.item.isShow;
    });

    const actions = {
      updatePublished: () => {
        let item = props.item;
        item.isShow = state.isShow;
        emit("updatePublished", item);
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>
