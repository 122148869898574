<template>
  <div class="brand-sns__item" @click="actions.selectSns()">
    <img class="icon" :src="spaceSns.icon" />
    <span class="sub-text-s2">
      {{ spaceSns.title }}
    </span>
  </div>
</template>

<script>
export default {
  name: "BrandSnsModalItem",
  props: {
    spaceSns: {
      type: Object,
      require: false,
    },
  },
  emits: ["selectSpaceSns"],
  setup(props, { emit }) {
    const actions = {
      selectSns: () => {
        emit("selectSpaceSns", props.spaceSns);
      },
    };

    return { actions };
  },
};
</script>

<style src="./style.css" scoped></style>
