<template>
  <base-modal
    title="배너 추가"
    sub-title="알리고 싶은 페이지를 배너와 함께 효과적으로 홍보해보세요."
    @closeModal="state.loading ? '' : $emit('hideModal')"
  >
    <template #modalBody>
      <div class="prev-area">
        <div class="banner-title sub-text-s2">
          PC 배너 이미지 (1200 px * 90 px)
        </div>

        <ent-brand-input-file-v2
          class="pc-banner-image input-file-comp"
          button-padding="8px 8px"
          :accepts="featuredImageAccepts"
          :src="state.widgetForm.featuredImage"
          :delete-image="true"
          @open-file-selector="actions.openFileStack('pc')"
          @delete-image="actions.deleteImage()"
        ></ent-brand-input-file-v2>
        <div class="banner-title sub-text-s2">
          모바일 배너 이미지 (350 px * 90 px)
        </div>
        <ent-brand-input-file-v2
          class="mo-banner-image input-file-comp"
          button-padding="8px 8px"
          :accepts="featuredImageAccepts"
          :src="state.widgetForm.mFeaturedImage"
          :delete-image="true"
          @open-file-selector="actions.openFileStack('m')"
          @delete-image="actions.deleteImage()"
        ></ent-brand-input-file-v2>

        <input-basic
          class="input-comp"
          label="브랜드 URL"
          placeholder="연결하고자 하는 URL을 입력해 주세요."
          :required="true"
          :default-value="state.widgetForm.url"
          @updateData="
            (value) => {
              actions.updateLinkUrl(value);
            }
          "
        ></input-basic>

        <input-basic
          class="input-comp"
          label="대체 텍스트"
          placeholder="이미지가 노출되지 않을 경우 대체해서 노출 할 텍스트를 입력해주세요"
          :required="true"
          :default-value="state.widgetForm.title"
          @updateData="
            (value) => {
              actions.updateLinkTitle(value);
            }
          "
        ></input-basic>
        <input-text-box-new
          class="input-comp"
          :model-value="state.widgetForm.description"
          label="배너 설명"
          placeholder="배너에 대한 간단한 설명 또는 메모를 입력해주세요"
          :height-resize="false"
          :rows="2"
          @update:modelValue="
            (value) => {
              actions.updateDescription(value);
            }
          "
        ></input-text-box-new>
      </div>

      <div class="btn-wrapper">
        <button-basic
          class="cancel-btn"
          bg-color="#ECF1F4"
          color="#0D0D10"
          text="뒤로"
          :disabled="state.loading"
          @action="actions.openListModal()"
        ></button-basic>

        <button-basic
          class="save-btn"
          text="추가"
          :disabled="state.loading || !state.activeCreateBtn"
          @action="actions.createWidget()"
        ></button-basic>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { computed, getCurrentInstance, reactive, ref } from "vue";
import { useStore } from "vuex";
import swal from "@/helper/swal";
import InputBasic from "@/components/console/inputs/InputBasic";
import BaseModal from "@/components/common/BaseModal.vue";
import FileStackService from "@/services/FileStackService";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import EntBrandInputFileV2 from "@/pages/console/EntBrand/EntBrandInputFileV2/EntBrandInputFileV2.vue";
import InputTextBoxNew from "@/components/console/inputs/InputTextBoxNew.vue";

export default {
  name: "AddBrandBannerWidgetModal",
  components: {
    InputTextBoxNew,
    EntBrandInputFileV2,
    ButtonBasic,
    InputBasic,
    BaseModal,
  },
  emits: ["hideModal", "saveComplete", "openWidgetListModal"],
  setup(props, { emit }) {
    const baseModal = ref(null);
    const contentDialog = ref(null);
    const contentModal = ref(null);
    const store = useStore();
    const { proxy } = getCurrentInstance();
    const featuredImageAccepts = ["jpg", "jpeg", "png", "webp"];

    const state = reactive({
      widgetForm: {
        type: 41,
        url: "",
        title: "",
        description: "",
        featuredImage: "",
        mFeaturedImage: "",
      },
      loading: false,
      isTitle: computed(() => {
        return state.widgetForm.title.trim().length > 0;
      }),
      isUrl: computed(() => {
        return state.widgetForm.url.trim().length > 0;
      }),
      activeCreateBtn: computed(() => {
        return (
          state.widgetForm.title.trim().length > 0 &&
          state.widgetForm.url.trim().length > 0 &&
          state.widgetForm.featuredImage.length > 0 &&
          state.widgetForm.mFeaturedImage.length > 0
        );
      }),
    });

    const actions = {
      openFileStack: (type) => {
        console.log(type);
        const mimeTypes = featuredImageAccepts.map((item) => {
          return proxy.$const.mimeTypes[item].value;
        });
        let fileStack = new FileStackService();

        fileStack.options.transformations.crop = false;
        fileStack.options.transformations.circle = false;
        // fileStack.options.transformations.crop.aspectRatio = aspectRatio;
        fileStack.options.accept = mimeTypes;
        fileStack.options.onFileUploadFinished = (fileMetaData) => {
          if (type === "m") {
            state.widgetForm.mFeaturedImage = fileMetaData.url;
          } else {
            state.widgetForm.featuredImage = fileMetaData.url;
          }
        };

        fileStack.open(fileStack.options);
      },
      deleteImage: (type) => {
        state[`${type}Image`] = null;
        emit("deleteImage", type);
      },
      updateLinkUrl: (value) => {
        state.widgetForm.url = value;
      },
      updateLinkTitle: (value) => {
        state.widgetForm.title = value;
      },
      updateDescription: (value) => {
        state.widgetForm.description = value;
      },
      createWidget: () => {
        state.loading = true;
        let formData = new FormData();
        console.log(state.widgetForm);
        formData.append("type", state.widgetForm.type);
        formData.append("url", state.widgetForm.url);
        formData.append("title", state.widgetForm.title);
        formData.append("description", state.widgetForm.description);
        formData.append("featuredImage", state.widgetForm.featuredImage);
        formData.append("mFeaturedImage", state.widgetForm.mFeaturedImage);

        store.dispatch("space/postSpaceWidget", { data: formData }).then(() => {
          swal.createCompleteToast();
          state.loading = false;
          emit("saveComplete");
        });
      },
      changeInputForm: () => {
        state.isUrlForm = false;
      },
      closeModal: () => {
        if (!state.loading) {
          emit("hideModal");
        }
      },
      openListModal: () => {
        emit("openWidgetListModal");
      },
    };

    return {
      baseModal,
      contentModal,
      contentDialog,
      state,
      actions,
    };
  },
};
</script>

<style src="./style.css" scoped></style>
