<template>
  <base-modal
    class="click-list-modal"
    :title="`${title} 클릭수`"
    @closeModal="$emit('hideModal')"
  >
    <template #modalBody>
      <div class="sub-text-s2 text-gray-second">
        방문자들이 가장 많이 클릭한 {{ title }} 순위
      </div>
      <hr class="hr-third" />
      <div class="click-list-card">
        <div class="click-list-data">
          <ul>
            <li
              v-for="(item, index) in list.slice(0, 5)"
              :key="index"
              class="data"
            >
              <div
                class="number"
                :class="index === 0 ? 'text-default' : 'text-gray-third'"
              >
                {{ index + 1 }}
              </div>
              <div v-if="type === 'sns'" class="title sns text-default">
                <span
                  class="bg-cover d-inline-block"
                  style="width: 32px; height: 32px"
                  :style="`background-image:url('https://cdn.bigc.im/statics/commons/icon/ic_${item.icon}.svg')`"
                ></span
                ><span>{{ item.title }}</span>
              </div>
              <div v-if="type !== 'sns'" class="title text-default">
                {{ item.title }}
              </div>

              <div class="count text-default">{{ item.counts }}</div>
            </li>
          </ul>
        </div>
      </div>
      <hr class="hr-third" />
      <button-basic
        text="확인"
        color="#0D0D10"
        bg-color="#ECF1F4"
        text-size="s1"
        @action="actions.closeModal()"
      ></button-basic>
    </template>
  </base-modal>
</template>

<script>
import { reactive } from "vue";
import BaseModal from "../../../../components/common/BaseModal.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";

export default {
  name: "ClickListModal",
  components: {
    ButtonBasic,
    BaseModal,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    list: {
      type: Object,
      required: true,
      default(rawProps) {
        console.log(rawProps);
        return rawProps;
      },
    },
    title: {
      type: String,
      required: true,
    },
  },
  emits: ["createWidget", "hideModal"],
  setup(props, { emit }) {
    const state = reactive({});

    const actions = {
      closeModal: () => {
        emit("hideModal");
      },
    };

    return { state, actions };
  },
};
</script>

<style scoped>
.click-list-modal .hr-third {
  margin-top: 20px;
  margin-bottom: 20px;
}

.click-list-card h3 {
  font-size: 16px;
  font-weight: 700;
  line-height: 125%;
  margin-bottom: 28px;
}

.click-list-card .data {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 14px;
  height: 48px;
  border: 1px solid #e6eaef;
  border-radius: 4px;
  padding: 8px 12px;
  margin-bottom: 12px;
}
.click-list-card .data:last-child {
  margin-bottom: 0;
}

.click-list-card .data div:nth-child(2) {
  flex-grow: 1;
}

.click-list-data ul {
  padding: 0;
  margin: 0;
}

.click-list-data .data .number {
  font-weight: 700;
  font-size: 14px;
  line-height: 18.2px;
  padding-right: 12px;
}

.click-list-data .data .title span:first-child {
  font-weight: 700;
  font-size: 14px;
  line-height: 18.2px;
}

.click-list-data .data .title.sns {
  display: flex;
  align-items: center;
}

.click-list-data .data .title.sns > span:first-child {
  width: 32px;
  height: 32px;
  background-size: cover;
  background-repeat: no-repeat;
}

.click-list-data .data .title.sns > span:last-child {
  padding-left: 8px;
}
</style>
