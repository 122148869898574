<template>
  <div>
    <card-basic class="profile-card">
      <div class="profile-section">
        <div class="profile-input-area">
          <h4>크리에이터 프로필</h4>
          <p class="sub-text-s2 text-gray-second desc">
            브랜드 홈의 프로필 영역에 보여지는 정보를 등록해주세요
          </p>

          <div class="user-avatar-area">
            <div class="user-avatar" @click="actions.openFileStack()">
              <avatar :avatar-img="state.avatar" size="100"></avatar>
              <div class="edit-icon">
                <edit-icon stroke-size="nomal"></edit-icon>
              </div>
            </div>
          </div>

          <div>
            <div class="creator-name">
              <input-basic
                placeholder="활동명 혹은 채널명을 입력해주세요."
                label="크리에이터명"
                :required="true"
                :default-value="state.creatorName"
                :max-length="20"
                :caption="`${state.creatorName.length}/20`"
                @updateData="(value) => actions.updateCreatorName(value)"
              ></input-basic>
            </div>
            <div class="creator-introduction">
              <!-- NOTE v-model에 emit 기능 적용하기 https://v3-migration.vuejs.org/breaking-changes/v-model.html-->
              <input-text-box
                label="간단한 소개 및 활동 계획"
                placeholder="크리에이터님에 대한 소개와 활동 계획을 입력해주세요
오른쪽 브랜드 홈에서 두 줄로 나오는게 가장 예쁘게 보여요
"
                :max-length="500"
                :rows="4"
                :height-resize="false"
                :border-none="false"
                :model-value="state.description"
                @update:modelValue="
                  (value) => {
                    actions.updateCreatorDescription(value);
                  }
                "
              ></input-text-box>
            </div>
          </div>
        </div>

        <button-basic
          class="create-btn"
          padding="11px 16px"
          text="저장"
          :disabled="!state.activeBtn"
          @action="actions.saveProfile()"
        ></button-basic>
      </div>
    </card-basic>
    <card-basic class="sns-card">
      <h4>소셜 미디어</h4>

      <p class="b-text-1">
        아직 연결된 소셜 미디어가 없습니다.<br />
        '+소셜 미디어 연결하기' 버튼을 클릭하여 소셜 미디어를 추가해주세요.
      </p>

      <button-basic
        class="create-btn social-btn"
        text="소셜 미디어 연결하기"
        icon-position="front"
        padding="11px 16px"
        @action="actions.openChannelListModal()"
      >
        <template #icon>
          <plus-icon fill-color="#ffffff"></plus-icon>
        </template>
      </button-basic>
      <template v-if="spaceChannels.length > 0">
        <ul class="channel-item-list">
          <li v-for="(item, index) in spaceChannels" :key="index">
            <ent-brand-social-item
              :channel="item"
              @open-delete-channel-modal="
                actions.openDeleteChannelModal(item.resourceId)
              "
            ></ent-brand-social-item>
          </li>
        </ul>
      </template>
    </card-basic>
    <card-basic class="footer-card">
      <h4>footer 문구 등록</h4>
      <p class="sub-text-s2 text-gray-second"></p>
      <div>
        <input-basic-new
          class="input-footer"
          label="하단에 노출될 저작권 표시 또는 저작권 소유자 정보를 입력해주세요"
          :default-value="state.footerCompany"
          @update-data="(value) => actions.updateFooterCompany(value)"
        ></input-basic-new>
      </div>

      <button-basic
        class="create-btn"
        text="저장"
        padding="11px 16px"
        :disabled="
          !state.activeSaveFooterCompanyBtn || state.isSavingFooterCompany
        "
        :is-loading="state.isSavingFooterCompany"
        @action="actions.saveFooterCompany()"
      ></button-basic>
    </card-basic>
    <card-basic class="about-description-form">
      <h4>About 페이지 내용 등록</h4>
      <p class="sub-text-s2 text-gray-second">
        나를 잘 표현 할 수 있는 소개 이미지와 소개 문구를 작성하여 About
        페이지를 꾸며보세요.
      </p>

      <div class="about-description">
        <quill-editor
          label="소개 문구 등록하기"
          placeholder="나를 표현 할 수 있 소개문구를 등록해보세요"
          :value="state.aboutPageDescription"
          editor-container-height="500"
          :embeddable-video="false"
          @updateValue="(value) => actions.updateDescription(value)"
        >
        </quill-editor>
      </div>

      <button-basic
        class="create-btn"
        text="저장"
        padding="11px 16px"
        :disabled="
          !state.activeSaveDescriptionBtn || state.isSavingAboutDescription
        "
        :is-loading="state.isSavingAboutDescription"
        @action="actions.saveAboutPageDescription()"
      ></button-basic>
    </card-basic>
    <card-basic class="secret-card">
      <div class="secret-area">
        <h4>접근 비밀번호 설정</h4>
        <input-switch
          v-model="state.spacePasswordForm.isSecret"
          :disabled="state.isSavingSpacePassword"
        ></input-switch>
      </div>
      <div class="password-area">
        <input-basic-new
          placeholder="비밀번호를 설정해주세요. 최소 4자리 입니다."
          :error-message="state.spacePasswordErrorMessage"
          :default-value="state.spacePasswordForm.password"
          @updateData="(value) => actions.updatePassword(value)"
        ></input-basic-new>
        <div class="sub-text-s2 text-gray-second caption">
          <p>비빌번호 설정 시 공백을 허용하지 않습니다.</p>
          <p>
            {{ curPasswordAnnotation }}
          </p>
        </div>
        <button-basic
          class="create-btn"
          text="저장"
          padding="11px 16px"
          :disabled="state.isSavingSpacePassword"
          :is-loading="state.isSavingSpacePassword"
          @action="actions.saveSpacePassword"
        ></button-basic>
      </div>
    </card-basic>
  </div>

  <teleport to="#teleport">
    <add-brand-channel-modal
      v-if="state.showAddChannelModal"
      class="space__channels-modal"
      :selected-sns="state.selectedSns"
      :is-header-section="false"
      :is-footer-section="false"
      @saveComplete="actions.updateChannel()"
      @hideModal="actions.hideAddChannelModal()"
      @openSpaceSnsModal="actions.showAddChannelModal('reset')"
    ></add-brand-channel-modal>

    <ent-brand-channel-list-modal
      v-if="state.showChannelListModal"
      class="space__sns-modal"
      :is-header-section="false"
      :is-footer-section="false"
      :const-channels="state.constChannels"
      @openInputUrl="
        (snsItem) => {
          actions.showAddChannelModal(snsItem);
        }
      "
      @hideModal="actions.hideChannelListModal()"
    >
    </ent-brand-channel-list-modal>
    <warning-modal
      v-if="state.showDeleteChannelModal"
      warning-title="삭제 하시겠습니까?"
      warning-text="SNS을 삭제하시면 추후에 다시 링크를 연결해야 합니다."
      confirm-text="삭제"
      @hideModal="actions.closeDeleteChannelModal()"
      @confirm="actions.deleteChannel()"
    ></warning-modal>
  </teleport>
</template>

<script>
import { computed, onBeforeMount, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import InputBasic from "../../../../components/console/inputs/InputBasic";
import InputTextBox from "../../../../components/console/inputs/InputTextBox";
import ButtonBasic from "../../../../components/console/buttons/ButtonBasic";
import FileStackService from "@/services/FileStackService";
import Avatar from "@/components/console/avatars/Avatar.vue";
import EditIcon from "@/components/console/icons/EditIcon";
import CardBasic from "@/components/console/cards/CardBasic.vue";
import EntBrandSocialItem from "@/pages/console/EntBrand/EntBrandSocialItem/EntBrandSocialItem.vue";
import EntBrandChannelListModal from "@/pages/console/EntBrand/EntBrandChannelListModal/EntBrandChannelListModal.vue";
import AddBrandChannelModal from "@/pages/console/EntBrand/AddBrandChannelModal/AddBrandChannelModal.vue";
import PlusIcon from "@/components/console/icons/PlusIcon.vue";
import swal from "@/helper/swal";
import WarningModal from "@/components/console/modals/WarningModal.vue";
import QuillEditor from "@/components/console/editors/QuillEditor.vue";
import apiConsole from "@/api";
import InputBasicNew from "@/components/console/inputs/InputBasicNew.vue";
import ApiService from "@/api";
import InputSwitch from "@/components/console/inputs/InputSwitch.vue";

export default {
  name: "EntBrandProfile",
  components: {
    InputSwitch,
    InputBasicNew,
    QuillEditor,
    WarningModal,
    PlusIcon,
    AddBrandChannelModal,
    EntBrandChannelListModal,
    EntBrandSocialItem,
    CardBasic,
    EditIcon,
    Avatar,
    ButtonBasic,
    InputTextBox,
    InputBasic,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    spaceChannels: {
      type: Array,
      default: () => {
        return [];
      },
    },
    space: {
      type: Object,
      required: true,
    },
  },
  emits: ["reloadIframe"],
  setup(props, { emit }) {
    const store = useStore();

    const state = reactive({
      creatorName: "",
      description: "",
      avatar: "",
      activeBtn: computed(() => {
        return state.creatorName && state.avatar;
      }),
      saveBtnDisabled: computed(() => {
        return state.creatorName.trim().length === 0;
      }),
      channels: [],
      showAddChannelModal: false,
      showChannelListModal: false,
      constChannels: computed(() => {
        return store.getters["channels/constChannels"];
      }),
      selectedSns: "",
      showDeleteChannelModal: false,
      isDeletingChannelResourceId: null,
      aboutPageDescription: "",
      activeSaveDescriptionBtn: computed(() => {
        return state.aboutPageDescription.length > 0;
      }),
      isSavingAboutDescription: false,
      footerCompany: "",
      activeSaveFooterCompanyBtn: computed(() => {
        return state.footerCompany.length > 0;
      }),
      isSavingFooterCompany: false,
      spacePasswordForm: {
        isSecret: false,
        password: "",
      },
      isSavingSpacePassword: false,
      spacePasswordErrorMessage: "",
      infoMessage: "",
    });

    const curPassword = computed(() => {
      let password = props.space.password;
      return password !== null ? password : null;
    });

    const curPasswordAnnotation = computed(() => {
      return curPassword.value ? `현재 비밀번호 : ${curPassword.value}` : "";
    });

    onBeforeMount(() => {
      state.creatorName = props.user.userProfile.displayName
        ? props.user.userProfile.displayName
        : "";
      state.description = props.user.userProfile.description
        ? props.user.userProfile.description
        : "";
      state.avatar = props.user.avatar ? props.user.avatar : "";
      state.aboutPageDescription = props.space.description
        ? props.space.description
        : "";
      state.footerCompany = props.space.footerCompany
        ? props.space.footerCompany
        : "";
      state.spacePasswordForm.isSecret = props.space.isSecret;
      store.dispatch("channels/getConstChannels");
    });

    const setPrivateSpace = () => {
      // 유저가 처음으로 브랜드홈 상태를 비공개로 설정하는데 비밀번호를 입력하지 않은 경우
      if (
        state.spacePasswordForm.isSecret &&
        curPassword.value === null &&
        state.spacePasswordForm.password.length === 0
      ) {
        state.spacePasswordErrorMessage = "비밀번호는 최소 4자리입니다.";
        return;
      }

      let payload = {
        isSecret: state.spacePasswordForm.isSecret,
        password: curPassword.value,
        passwordConfirmation: curPassword.value,
      };

      // 유저가 입력한 경우
      if (state.spacePasswordForm.password.length !== 0) {
        // 공백 체크
        let hasSpace = /\s/.test(state.spacePasswordForm.password);
        if (hasSpace) {
          state.spacePasswordErrorMessage = "비밀번호를 확인해주세요.";
          return;
        }

        // 앞 뒤 공백 간격 제거
        payload.password = state.spacePasswordForm.password.trim();
        payload.passwordConfirmation = state.spacePasswordForm.password.trim();
        state.spacePasswordForm.password =
          state.spacePasswordForm.password.trim();
      }

      if (payload.password !== null && payload.password.trim().length < 4) {
        state.spacePasswordErrorMessage = "비밀번호는 최소 4자리입니다.";
        return;
      }

      state.isSavingSpacePassword = true;

      store
        .dispatch("space/postSpacesSecret", { data: payload })
        .then((res) => {
          if (res.success) {
            state.spacePasswordErrorMessage = "";
            emit("reloadIframe");
            swal.editCompleteToast();
          } else {
            if (res.message === "validation.min.string") {
              swal.errorToast("비밀번호는 최소 4자리 입니다.");
            } else {
              swal.errorToast(res.message);
            }
          }
          state.isSavingSpacePassword = false;
        });
    };

    const actions = {
      saveProfile: () => {
        let payload = {
          displayName: state.creatorName,
          avatar: state.avatar,
          description: state.description,
        };
        store.dispatch("auth/putUserProfile", payload).then((res) => {
          emit("reloadIframe");
          swal.createCompleteToast();
        });
      },
      openFileStack: () => {
        const fileStack = new FileStackService();

        fileStack.options.transformations.crop = false;
        fileStack.options.onFileUploadFinished = (fileMetaData) => {
          const formData = new FormData();
          formData.append("file", fileMetaData.url);

          ApiService.postAttachment(formData).then((res) => {
            if (res.data.success) {
              state.avatar = res.data.data.url;
            }
          });
        };

        fileStack.open(fileStack.options);
      },
      updateCreatorName: (value) => {
        state.creatorName = value;
      },
      updateCreatorDescription: (value) => {
        state.description = value;
      },
      showGuideAlert: () => {
        swal.messageAlert("모바일 기기에서만 가능 합니다.");
      },
      openChannelListModal: () => {
        state.showChannelListModal = true;
      },
      hideChannelListModal: () => {
        state.showChannelListModal = false;
      },
      openAddChannelModal: () => {
        state.showAddChannelModal = true;
      },
      showAddChannelModal: async (data) => {
        if (data === "reset") {
          state.selectedSns = "";
          state.showChannelListModal = true;
          state.showAddChannelModal = false;
          return;
        }
        state.selectedSns = data;
        state.showChannelListModal = false;
        state.showAddChannelModal = true;
      },
      hideAddChannelModal: () => {
        state.selectedSns = "";
        state.showAddChannelModal = false;
      },
      openDeleteChannelModal: (resourceId) => {
        state.showDeleteChannelModal = true;
        state.isDeletingChannelResourceId = resourceId;
      },
      closeDeleteChannelModal: () => {
        state.showDeleteChannelModal = false;
        state.isDeletingChannelResourceId = null;
      },
      updateChannel: () => {
        emit("reloadIframe");
        actions.hideAddChannelModal();
      },
      deleteChannel: () => {
        let payload;
        store
          .dispatch("space/deleteSpaceChannel", {
            spaceChannelResourceId: state.isDeletingChannelResourceId,
          })
          .then(() => {
            emit("reloadIframe");
            swal.deleteCompleteToast();
            state.isDeletingChannelResourceId = null;
            state.showDeleteChannelModal = false;
          });
      },
      updateDescription: (value) => {
        state.aboutPageDescription = value;
      },
      saveAboutPageDescription: () => {
        state.isSavingAboutDescription = true;
        apiConsole
          .postSpaceNavigationsDescription({
            description: state.aboutPageDescription,
          })
          .then(() => {
            emit("reloadIframe");
            swal.editCompleteToast();
            state.isSavingAboutDescription = false;
          })
          .catch((e) => {
            swal.errorToast(e.message);
            state.isSavingAboutDescription = false;
          });
      },
      updateFooterCompany: (value) => {
        state.footerCompany = value;
      },
      saveFooterCompany: () => {
        let payload = {
          headerType: store.getters["space/space"].headerType,
          footerCompany: state.footerCompany,
          information: store.getters["space/space"].information,
        };
        state.isSavingFooterCompany = true;

        store.dispatch("space/putSpace", { data: payload }).then(() => {
          emit("reloadIframe");
          swal.editCompleteToast();
          state.isSavingFooterCompany = false;
        });
      },
      updatePassword: (value) => {
        state.spacePasswordForm.password = value;
      },
      saveSpacePassword: () => {
        setPrivateSpace();
      },
    };

    return {
      state,
      curPassword,
      curPasswordAnnotation,
      actions,
    };
  },
};
</script>

<style src="./style.css" scoped></style>
