<template>
  <custom-base-modal-brand
    class="add-widget-modal"
    title="위젯 추가하기"
    sub-title="나에게 맞는 위젯을 추가하여 브랜드 홈을 완성해보세요"
    @closeModal="$emit('hideModal')"
  >
    <template #modalBody>
      <ul class="widget-list">
        <li
          v-for="(spaceWidgetType, index) in spaceWidgetTypes"
          :key="`widget-${index}`"
          style="width: 120px"
          class="widget-item"
          :class="{
            invisible:
              (spaceWidgetType.type === 35 && !state.isAdmin) ||
              (spaceWidgetType.type === 43 && !state.isAdmin) ||
              (spaceWidgetType.type === 47 && !state.isAdmin) ||
              (spaceWidgetType.type === 49 && !state.isAdmin),
          }"
        >
          <tooltip
            :triggers="['hover']"
            :is-disabled="
              spaceWidgetType.isLocked ||
              spaceWidgetType.type === 1 ||
              spaceWidgetType.type === 5 ||
              spaceWidgetType.type === 7 ||
              spaceWidgetType.type === 9 ||
              (!state.isStarter && spaceWidgetType.type === 3) ||
              (!state.isStarter && spaceWidgetType.type === 23) ||
              (!state.isStarter && spaceWidgetType.type === 13) ||
              (!state.isStarter && spaceWidgetType.type === 27) ||
              (!state.isStarter && spaceWidgetType.type === 25) ||
              (!state.isStarter && spaceWidgetType.type === 11) ||
              (!state.isStarter && spaceWidgetType.type === 35) ||
              (state.isEnterprise && spaceWidgetType.type === 41) ||
              (state.isPrePro && spaceWidgetType.type === 41) ||
              (state.isPro && spaceWidgetType.type === 41) ||
              (!state.isStarter && spaceWidgetType.type === 43) ||
              (!state.isStarter && spaceWidgetType.type === 47) ||
              (!state.isStarter && spaceWidgetType.type === 49)
            "
            container=".add-widget-modal"
            icon-color="#FF4646"
            placement="bottom"
            :delay="{ show: 0, hide: 600 }"
            :distance="-30"
          >
            <template #button>
              <ent-brand-widget-item
                :item="spaceWidgetType"
                @createWidget="
                  (type) => {
                    actions.createWidget(type);
                  }
                "
                @editWidget="
                  (widget) => {
                    actions.showEditLinkWidgetModal(widget);
                  }
                "
                @saveComplete="actions.updateServiceSpace()"
              ></ent-brand-widget-item>
            </template>
            <template #tooltip>
              <p class="tooltip-content sub-text-s3 text-gray-second">
                <span v-if="state.isStarter">
                  <span>
                    PRO가 되면 사용할 수 있어요! <br />
                    <a
                      href="https://tally.so/r/3NrrKl"
                      target="_blank"
                      class="text-blue-50"
                      >지금 바로 PRO에 도전해보세요!</a
                    >
                  </span>
                </span>
              </p>
            </template>
          </tooltip>
        </li>
      </ul>
    </template>
  </custom-base-modal-brand>
</template>

<script>
import { reactive, computed, onMounted } from "vue";
import CustomBaseModalBrand from "@/pages/console/EntBrand/CustomBaseModalBrand/CustomBaseModalBrand.vue";
import EntBrandWidgetItem from "@/pages/console/EntBrand/EntBrandWidgetItem/EntBrandWidgetItem.vue";
import { useStore } from "vuex";
import Tooltip from "@/components/console/tooltip/Tooltip.vue";

export default {
  name: "AddWidgetModal",
  components: { Tooltip, EntBrandWidgetItem, CustomBaseModalBrand },
  props: {
    constChannels: {
      type: Array,
      required: true,
    },
    spaceWidgetTypes: {
      type: Array,
      required: true,
    },
  },
  emits: ["openInputUrl", "hideModal", "createWidget"],
  setup(props, { emit }) {
    const store = useStore();

    const state = reactive({
      constChannels: props.constChannels,
      searchedWord: "",
      filterdSnsItems: computed(() => {
        let searchedWord = state.searchedWord.trim();
        if (searchedWord) {
          let result = state.constChannels.filter((item) =>
            item.title.includes(searchedWord.toUpperCase())
          );
          return result;
        }
        return state.constChannels;
      }),
      bannerWidget: {
        code: "banner",
        description: "광고 배너 위젯",
        images:
          "https://d1kclliheqmc75.cloudfront.net/statics/commons/widget/type/widget_type_35.png",
        isAvailable: true,
        isLocked: false,
        title: "배너 생성",
        type: 100,
      },
      isStarter: computed(() => {
        return store.getters["auth/isStarter"];
      }),
      isPrePro: computed(() => {
        return store.getters["auth/isPrePro"];
      }),
      isPro: computed(() => {
        return store.getters["auth/isPro"];
      }),
      isEnterprise: computed(() => {
        return store.getters["auth/isEnterprise"];
      }),
      isAdmin: computed(() => {
        return (
          store.getters["auth/user"].isAdmin ||
          store.getters["auth/user"].isStaff
        );
      }),
    });

    const actions = {
      onBtnSpaceSnsSelect: (snsItem) => {
        emit("openInputUrl", snsItem);
      },
      createWidget: (type) => {
        console.log("createWidget", type);
        if (state.isStarter) {
          if (
            type === 3 ||
            type === 23 ||
            type === 13 ||
            type === 27 ||
            type === 25 ||
            type === 11 ||
            type === 41 ||
            type === 35 ||
            type === 43
          ) {
            return;
          } else {
            emit("createWidget", type);
          }
        } else {
          emit("createWidget", type);
        }
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>
