<template>
  <div class="widget-item">
    <div class="info">
      <div
        class="featured-img"
        :style="`background-image: url(${channel.icon});`"
      ></div>

      <div class="meta-info">
        <p class="title text-default">{{ channel.code }}</p>
        <p class="description text-gray-second">
          {{ channel.url }}
        </p>
      </div>
    </div>

    <div class="btn-wrapper">
      <button-basic
        class="delete-btn"
        bg-color="transparent"
        padding="8px"
        @action="actions.openDeleteChannelModal()"
      >
        <template #icon>
          <delete-icon
            width="20"
            height="20"
            fill-color="#FF4646"
          ></delete-icon>
        </template>
      </button-basic>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import DeleteIcon from "@/components/console/icons/DeleteIcon.vue";

export default {
  name: "ChannelItem",
  components: {
    DeleteIcon,
    ButtonBasic,
  },
  props: {
    channel: {
      type: Object,
      required: false,
    },
  },
  emits: ["openDeleteChannelModal"],
  setup(props, { emit }) {
    const state = reactive({
      widgetType: "",
      url: "",
    });

    const actions = {
      openDeleteChannelModal: () => {
        emit("openDeleteChannelModal");
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>
