<template>
  <div v-if="!state.isAdmin" class="brand-insight">
    <p class="sub-text-s2 text-gray-second">
      현재 준비중인 페이지입니다. <br />빠른 시일내에 준비하여 찾아뵙겠습니다.
    </p>
    <img
      class="lock-img__pc"
      src="/assets/images/space/brand_insight_lock_pc_comp.png"
    />
    <img
      class="lock-img__mo"
      src="/assets/images/space/brand_insight_lock_mo_comp.png"
    />
  </div>
  <div
    v-if="state.isAdmin"
    class="brand-insight-setting brand-insight-container"
  >
    <div class="main-dashboard">
      <div
        v-for="(item, index) in firstData"
        :key="index"
        class="main-dashboard-item"
      >
        <!--        text-end text-white insight-point-size-->
        <div class="amount-point">
          <span class="text-gray-reverse point">
            {{ item.points }}
          </span>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path d="M8 5L11 10L5 10L8 5Z" fill="#00A3FF" />
            </svg>
          </span>
          <span class="text-blue-50 sub-text-s3">{{ item.amount }}</span>
        </div>

        <h3 class="title sub-text-s3 text-gray-third">
          {{ item.title }}
          <help-tooltip
            v-if="index == 2"
            icon-color="#D0D5DE"
            tooltip-text="조회수 대비 클릭수 비율(%)"
          ></help-tooltip>
        </h3>
      </div>
    </div>
    <div class="grid-area">
      <div class="grid-item days-dashboard">
        <button-dropdown>
          <template #button>
            <button class="dropdown-btn h4">
              지난 {{ state.graphPeriod }} 일
              <arrow-icon direction="down" stroke-size="normal"></arrow-icon>
            </button>
          </template>
          <template #dropdownList>
            <ul class="dropdown-list">
              <li>
                <button
                  v-close-popper
                  class="sub-text-s2"
                  :class="{ 'text-gray-third': state.graphPeriod === 7 }"
                  @click="actions.viewWeeksData(7)"
                >
                  지난 7일
                </button>
              </li>
              <li>
                <button
                  v-close-popper
                  class="sub-text-s2"
                  :class="{ 'text-gray-third': state.graphPeriod === 30 }"
                  @click="actions.viewWeeksData(30)"
                >
                  지난 30일
                </button>
              </li>
            </ul>
          </template>
        </button-dropdown>
        <div class="bg-gray-01 days-dashboard-items">
          <div
            v-for="(item, index) in secondData"
            :key="index"
            class="bg-404661 item"
          >
            <div class="amount">
              {{ item.points }}
            </div>

            <div class="label">
              <span
                class="dot"
                :class="{
                  'custom-bg-purple-50': index === 0,
                  'bg-orange-50': index === 1,
                  'bg-gray-040': index === 2,
                }"
              ></span>
              <span class="text-gray-060 sub-text-s3">
                {{ item.title }}
              </span>
              <span v-if="index === 2">
                <help-tooltip
                  icon-color="#818287"
                  tooltip-text="조회수 대비 클릭수 비율(%)"
                ></help-tooltip>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="bg-gray-02 days-dashboard-line-chart insight-line-chart grid-item"
      >
        <h2 class="fs-4 lh-20 fw-bold">그래프</h2>
        <apexchart
          type="line"
          :options="state.lineChartOption"
          :series="state.lineChartSeries"
        ></apexchart>
      </div>

      <div class="channel-card grid-item">
        <!--	유입 채널  -->
        <h4 class="h4">유입 채널</h4>

        <div class="channel-static">
          <div class="chart">
            <apexchart
              width="390"
              :options="state.channelChartOption.chartOptions"
              :series="state.channelChartOption.series"
            ></apexchart>
            <div class="data">
              <div class="percent">56%</div>
              <div class="top-channel">
                <span class="text-second sub-text-s2">직접 유입</span>
                <span class="text-blue-50 sub-text-s3">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path d="M8 5L11 10L5 10L8 5Z" fill="#00A3FF" />
                    </svg>
                  </span>
                  8975</span
                >
              </div>
            </div>
          </div>

          <ul class="channel-list">
            <li
              v-for="(item, index) in pieData"
              :key="index"
              class="sub-text-s2 bg-gray-studio"
            >
              <div class="dot"></div>
              <span class="label text-gray-default">{{ item.title }}</span>
              <span class="count text-gray-second">{{ item.counts }}</span>
            </li>
          </ul>
        </div>
      </div>

      <!--    유입 국가-->
      <div class="inflow-country grid-item">
        <h3>유입 국가</h3>
        <ul class="inflow-country-items">
          <li
            v-for="(item, index) in inflowCountry"
            :key="index"
            class="bg-gray-studio"
          >
            <div class="text-default sub-text-s2 country">
              {{ item.country }}
            </div>
            <div class="line-graph">
              <div
                class="value"
                :style="{ width: `${item.inflowCount}%` }"
              ></div>
            </div>
            <div class="text-second sub-text-s3 count">
              {{ item.inflowCount }}%
            </div>
          </li>
        </ul>
      </div>

      <!--    소셜 미디어 클릭수-->
      <div class="click-list-wrapper grid-item">
        <div class="click-list-card">
          <!--        v-on:click="socialData.length > 5 ? "-->
          <h3
            :class="{
              'click-list-modal-btn': `${socialData.length > 5}`,
            }"
            @click="
              socialData.length > 5 ? actions.showSocialClickListModal() : ''
            "
          >
            소셜미디어 클릭수
          </h3>
          <div class="click-list-data">
            <ul>
              <li
                v-for="(item, index) in socialData.slice(0, 5)"
                :key="index"
                class="data"
              >
                <div
                  class="number"
                  :class="index === 0 ? 'text-default' : 'text-gray-third'"
                >
                  {{ index + 1 }}
                </div>
                <div class="title sns text-default">
                  <span
                    class="bg-cover d-inline-block"
                    style="width: 32px; height: 32px"
                    :style="`background-image:url('https://cdn.bigc.im/statics/commons/icon/ic_${item.icon}.svg')`"
                  ></span
                  ><span>{{ item.title }}</span>
                </div>
                <div class="count text-default">{{ item.counts }}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!--    링크 클릭수 -->
      <div class="click-list-wrapper grid-item">
        <div class="click-list-card">
          <!--        v-on:click="socialData.length > 5 ? "-->
          <h3
            :class="{ 'click-list-modal-btn': `${linkData.length > 5}` }"
            @click="linkData.length > 5 ? actions.showLinkClickListModal() : ''"
          >
            링크 클릭수
          </h3>
          <div class="click-list-data">
            <ul>
              <li
                v-for="(item, index) in linkData.slice(0, 5)"
                :key="index"
                class="data"
              >
                <div
                  class="number"
                  :class="index === 0 ? 'text-default' : 'text-gray-third'"
                >
                  {{ index + 1 }}
                </div>
                <div class="title text-default">
                  {{ item.title }}
                </div>
                <div class="count text-default">{{ item.counts }}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!--    메뉴 위젯 클릭수 -->
      <div class="click-list-wrapper grid-item">
        <div class="click-list-card">
          <!--        v-on:click="socialData.length > 5 ? "-->
          <h3
            :class="{ 'click-list-modal-btn': `${menuData.length > 5}` }"
            @click="
              menuData.length > 5 ? actions.showWidgetClickListModal() : ''
            "
          >
            메뉴・위젯 클릭수
          </h3>
          <div class="click-list-data">
            <ul>
              <li
                v-for="(item, index) in menuData.slice(0, 5)"
                :key="index"
                class="data"
              >
                <div
                  class="number"
                  :class="index === 0 ? 'text-default' : 'text-gray-third'"
                >
                  {{ index + 1 }}
                </div>
                <div class="title sns text-default">
                  {{ item.title }}
                </div>
                <div class="count text-default">{{ item.counts }}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <teleport :disabled="true">
    <!--      sns-->
    <click-list-modal
      v-if="state.showSocialClickListModal"
      :is-header-section="true"
      :is-header-underline="false"
      :is-footer-section="false"
      title="소셜 미디어"
      type="sns"
      :list="socialData"
      @hideModal="actions.hideSocialClickListModal()"
    ></click-list-modal>
    <!--      link-->
    <click-list-modal
      v-if="state.showLinkClickListModal"
      :is-header-section="true"
      :is-footer-section="false"
      title="링크"
      type="link"
      :list="linkData"
      @hideModal="actions.hideLinkClickListModal()"
    ></click-list-modal>
    <!--      menu-->
    <click-list-modal
      v-if="state.showWidgetClickListModal"
      :is-header-section="true"
      :is-footer-section="false"
      title="메뉴 • 위젯"
      type="widget"
      :list="menuData"
      @hideModal="actions.hideWidgetClickListModal()"
    ></click-list-modal>
  </teleport>
</template>

<script>
import { ref, reactive, computed } from "vue";
import ArrowIcon from "@/components/console/icons/ArrowIcon.vue";
import ClickListModal from "@/pages/console/EntBrand/ClickListModal/ClickListModal.vue";
import ButtonDropdown from "@/components/console/buttons/ButtonDropdown.vue";
import HelpTooltip from "@/components/console/tooltip/HelpTooltip.vue";
import { useStore } from "vuex";
export default {
  name: "EntBrandInsightSetting",
  components: {
    HelpTooltip,
    ButtonDropdown,
    ArrowIcon,
    ClickListModal,
  },
  setup() {
    const socialClickData = ref();
    const linkClickData = ref();
    const menuClickData = ref();

    const store = useStore();

    const state = reactive({
      showSocialClickData: false,
      showLinkClickData: false,
      showMenuClickData: false,
      lineChartSeries: [
        {
          name: "클릭수",
          data: [
            1900, 1800, 2000, 1850, 1850, 1900, 2000, 1800, 2000, 1700, 1600,
            1500, 1500, 1700, 1900,
          ],
        },
        {
          name: "CTR",
          data: [
            1900 / 900,
            1800 / 1000,
            2000 / 800,
            1850 / 785,
            1850 / 1350,
            1900 / 1200,
            2000 / 1000,
            1800 / 900,
            2000 / 1200,
            1700 / 1200,
            1600 / 1300,
            1500 / 1100,
            1500 / 1200,
            1700 / 900,
            1900 / 1000,
          ],
        },
        {
          name: "조회수",
          data: [
            900, 1000, 800, 785, 1350, 1200, 1000, 900, 1200, 1200, 1300, 1100,
            1200, 900, 1000,
          ],
        },
      ],
      lineChartOption: {
        chart: {
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 3,
          curve: "smooth",
          dashArray: [0, 3, 0],
        },
        xaxis: {
          categories: [
            "2",
            "4",
            "6",
            "8",
            "10",
            "12",
            "14",
            "16",
            "18",
            "20",
            "22",
            "24",
            "26",
            "28",
            "30",
          ],
        },
        yaxis: [
          {
            title: {
              text: "횟수",
              offsetX: 0,
              offsetY: 0,
              style: {
                color: "#BABDC3",
                fontSize: "11px",
                cssClass: "apexcharts-xaxis-title-test",
              },
            },
            tickAmount: 5,
            min: 0,
            max: 5000,
            labels: {
              min: 0,
              max: 5000,
              style: {
                colors: "#BABDC3",
                fontSize: "11px",
              },
              formatter: (value) => {
                return value;
              },
            },
          },
          {
            opposite: true,
            labels: {
              min: 0,
              max: 100,
              style: {
                colors: "#BABDC3",
              },
              formatter: (value) => {
                return `${value * 100}%`;
              },
            },
            title: {
              text: "비율",
              style: {
                color: "#BABDC3",
                fontSize: "11px",
              },
            },
          },
        ],
        tooltip: {
          y: [
            {
              title: {
                formatter: function (val) {
                  return val;
                },
              },
            },
            {
              title: {
                formatter: function (val) {
                  return val;
                  // val +
                  // "" +
                  // opts.series[opts.seriesIndex][opts.dataPointIndex].toFixed(
                  //   2
                  // ) + "%"
                },
              },
            },
            {
              title: {
                formatter: function (val) {
                  return val;
                },
              },
            },
          ],
        },
        grid: {
          borderColor: "#f1f1f1",
        },
        colors: ["#FF4646", "#BABDC3", "#8E1EFF"],
      },
      channelChartOption: {
        series: [8975, 3023, 1832, 947, 808, 402, 352, 302, 280, 250],
        chartOptions: {
          chart: {
            type: "donut",
            offsetY: -10,
            toolbar: {
              show: false,
            },
            customScale: 0.5,
            width: 540,
            height: 540,
          },
          title: {
            show: false,
          },
          legend: {
            show: false,
          },
          plotOptions: {
            pie: {
              donut: {
                size: "90%",
              },
              offsetY: 30,
            },
          },
          dataLabels: {
            enabled: false,
          },
          colors: [
            "#b28bff",
            "#4ec53d",
            "#f4b935",
            "#5186ec",
            "#2260d9",
            "#f9e000",
            "#ff0000",
            "#d823cc",
            "#25b6f4",
            "#d0d5de",
          ],
          labels: [
            "직접유입",
            "네이버 블로그",
            "네이버 검색",
            "구글",
            "페이스북",
            "라인",
            "유튜브",
            "인스타그램",
            "트위터",
            "기타",
          ],
        },
      },
      showSocialClickListModal: false,
      showLinkClickListModal: false,
      showWidgetClickListModal: false,
      graphPeriod: 7,
      isAdmin: computed(() => {
        return (
          store.getters["auth/user"].isAdmin ||
          store.getters["auth/user"].isStaff
        );
      }),
    });

    const firstData = [
      {
        title: "전체 조회수",
        amount: "2,000",
        points: "120,000",
      },
      {
        title: "전체 클릭수",
        amount: "520",
        points: "150,000",
      },
      {
        title: "전체 CTR",
        amount: "26%",
        points: "125%",
      },
    ];

    const secondData = [
      {
        title: "조회수 (회)",
        points: "2,560",
      },
      {
        title: "클릭수 (회)",
        points: "4,170",
      },
      {
        title: "CTR",
        points: "162%",
      },
    ];

    const pieData = [
      { title: "직접 유입", counts: 8975 },
      { title: "네이버", counts: 3023 },
      { title: "다음", counts: 1832 },
      { title: "구글", counts: 947 },
      { title: "페이스북", counts: 808 },
      { title: "카카오", counts: 402 },
      { title: "유튜브", counts: 352 },
      { title: "인스타그램", counts: 302 },
      { title: "트위터", counts: 288 },
      { title: "기타", counts: 250 },
    ];

    const inflowCountry = [
      {
        id: 1,
        country: "대한민국",
        inflowCount: 76,
      },
      {
        id: 2,
        country: "미국",
        inflowCount: 12,
      },
      {
        id: 3,
        country: "일본",
        inflowCount: 3,
      },
      {
        id: 4,
        country: "호주",
        inflowCount: 1,
      },
      {
        id: 5,
        country: "캐나다",
        inflowCount: 1,
      },
    ];

    const socialData = [
      {
        title: "인스타",
        counts: "120",
        icon: "instagram",
      },
      {
        title: "유튜브",
        counts: "80",
        icon: "youtube",
      },
      {
        title: "트위터",
        counts: "64",
        icon: "twitter",
      },
      {
        title: "틱톡",
        counts: "32",
        icon: "tiktok",
      },
      {
        title: "페이스북",
        counts: "12",
        icon: "facebook",
      },
      {
        title: "트위치",
        counts: "10",
        icon: "twitch",
      },
      {
        title: "페이스북",
        counts: "8",
        icon: "facebook",
      },
      {
        title: "페이스북",
        counts: "8",
        icon: "facebook",
      },
      {
        title: "페이스북",
        counts: "3",
        icon: "facebook",
      },
      {
        title: "페이스북",
        counts: "2",
        icon: "facebook",
      },
      {
        title: "페이스북",
        counts: "1",
        icon: "facebook",
      },
    ];

    const linkData = [
      {
        title: "W 코리아 인터뷰",
        counts: "987",
      },
      {
        title: "W 코리아 인터뷰",
        counts: "543",
      },
      {
        title: "W 코리아 인터뷰",
        counts: "210",
      },
      {
        title: "W 코리아 인터뷰",
        counts: "98",
      },
      {
        title: "W 코리아 인터뷰",
        counts: "76",
      },
    ];
    const menuData = [
      {
        title: "Premium Content",
        counts: "9,876",
      },
      {
        title: "Link",
        counts: "8,765",
      },
      {
        title: "SNS Feed",
        counts: "7,654",
      },
      {
        title: "Schedule",
        counts: "987",
      },
      {
        title: "Upcoming Event",
        counts: "65",
      },
    ];

    const actions = {
      showSocialClickListModal: () => {
        state.showSocialClickListModal = true;
      },
      showLinkClickListModal: () => {
        state.showLinkClickListModal = true;
      },
      showWidgetClickListModal: () => {
        state.showWidgetClickListModal = true;
      },
      hideSocialClickListModal: () => {
        state.showSocialClickListModal = false;
      },
      hideLinkClickListModal: () => {
        state.showLinkClickListModal = false;
      },
      hideWidgetClickListModal: () => {
        state.showWidgetClickListModal = false;
      },
      viewWeeksData: (days) => {
        state.graphPeriod = days;
      },
    };

    return {
      state,
      actions,
      firstData,
      secondData,
      pieData,
      socialData,
      linkData,
      menuData,
      socialClickData,
      linkClickData,
      menuClickData,
      inflowCountry,
    };
  },
};
</script>

<style src="./style.css" scoped></style>
