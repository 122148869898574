<template>
  <base-modal
    title="투표 열기"
    sub-title="팔로워의 의견을 듣고 싶다면 투표기능을 사용해보세요 (투표는 '투표관리'에서 만들 수 있어요)"
    @closeModal="state.loading ? '' : $emit('hideModal')"
  >
    <template #modalBody>
      <div class="input-wrapper">
        <input-select-small
          v-model="state.pollWidgetForm.resourceId"
          label="연결할 투표 정보 선택하기"
          placeholder="투표 주제를 선택하세요"
          :list="state.polls"
        ></input-select-small>
      </div>
      <div class="btn-wrapper">
        <button-basic
          class="cancel-btn"
          bg-color="#ECF1F4"
          color="#0D0D10"
          text="뒤로"
          @click="actions.openListModal()"
        ></button-basic>
        <button-basic
          class="save-btn"
          :disabled="!state.isButtonEnabled"
          text="추가"
          @click="actions.createWidget()"
        ></button-basic>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { computed, onBeforeMount, reactive } from "vue";
import { useStore } from "vuex";
import swal from "../../../../helper/swal";
import BaseModal from "../../../../components/common/BaseModal.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import InputSelectSmall from "@/components/console/inputs/InputSelectSmall.vue";
import { transformKeyFormat } from "@/helper/brandPage";

export default {
  name: "AddBrandPollWidgetModal",
  components: {
    InputSelectSmall,
    ButtonBasic,
    BaseModal,
  },
  props: {
    polls: {
      type: Array,
      required: true,
    },
  },
  emits: ["saveComplete", "hideModal", "openWidgetListModal"],
  setup(props, { emit }) {
    const store = useStore();
    const state = reactive({
      pollWidgetForm: {
        type: 43,
        resourceId: "",
      },
      isButtonEnabled: computed(() => {
        return !!state.pollWidgetForm.resourceId;
      }),
      loading: false,
      polls: [],
    });

    onBeforeMount(() => {
      let polls = props.polls;
      state.polls = polls.length > 0 ? transformKeyFormat(polls) : [];
    });

    const actions = {
      updateTitle: (data) => {
        state.fundingForm.title = data;
      },
      updateDescription: (data) => {
        state.fundingForm.description = data;
      },
      createWidget: () => {
        state.loading = true;
        let formData = new FormData();

        formData.append("type", state.pollWidgetForm.type);
        formData.append("resourceId", state.pollWidgetForm.resourceId);

        store.dispatch("space/postSpaceWidget", { data: formData }).then(() => {
          swal.createCompleteToast();
          state.loading = false;
          emit("saveComplete");
        });
      },
      closeModal: () => {
        if (!state.loading) {
          emit("hideModal");
        }
      },
      openListModal: () => {
        emit("openWidgetListModal");
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>
