<template>
  <div>
    <card-basic class="mobile-guide__card">
      <h4>프로필 레이아웃 설정</h4>
      <p class="sub-text-s2 text-gray-second">
        모바일에서 레이아웃을 설정해보세요.
      </p>

      <p class="b-text-1">
        확인 버튼을 누르시면 모바일 화면으로 전환하여 레이아웃을 설정할 수
        있어요. 설정을 완료하시면 관리화면 상단에 완료 버튼을 통해 다시 스튜디오
        화면으로 전환할 수 있어요.
      </p>

      <button-basic
        text="확인"
        @action="actions.showGuideAlert()"
      ></button-basic>
    </card-basic>

    <div class="widget-create-btn">
      <button-basic
        text="위젯 추가하기"
        icon-position="front"
        padding="11px 16px"
        @action="actions.openAddWidgetModal()"
      >
        <template #icon>
          <plus-icon fill-color="#ffffff"></plus-icon>
        </template>
      </button-basic>
    </div>

    <ul class="widget-item-list">
      <draggable
        v-model="state.widgets"
        item-key="resourceId"
        handle=".handle"
        @change="actions.sortWidgets(state.widgets)"
      >
        <template #item="{ element }">
          <li>
            <widget-item
              :widget="element"
              :draggable="true"
              :is-sorting="state.isSorting"
              @edit-link-widget="
                (widgetForm) => {
                  actions.updateWidget(widgetForm, element.resourceId);
                }
              "
              @edit-upcoming-widget="
                (upcomingWidgetForm) => {
                  actions.updateUpcomingWidget(
                    upcomingWidgetForm,
                    element.resourceId
                  );
                }
              "
              @edit-banner-widget="
                (bannerWidgetForm) => {
                  actions.updateBannerWidget(
                    bannerWidgetForm,
                    element.resourceId
                  );
                }
              "
              @edit-poll-widget="
                (pollWidgetForm) => {
                  actions.updatePollWidget(pollWidgetForm, element.resourceId);
                }
              "
              @edit-embed-widget="
                (embedWidgetForm) => {
                  actions.updateEmbedWidget(
                    embedWidgetForm,
                    element.resourceId
                  );
                }
              "
              @update-published="
                (isPublished) => {
                  actions.updateIsPublished(isPublished, element.resourceId);
                }
              "
              @open-delete-widget-modal="
                actions.openDeleteWidgetModal(element.resourceId)
              "
            ></widget-item>
          </li>
        </template>
      </draggable>
    </ul>

    <teleport to="#teleport">
      <add-brand-link-widget-modal
        v-if="state.showAddLinkWidgetModal"
        @save-complete="actions.hideAddLinkWidgetModal()"
        @open-widget-list-modal="
          actions.openAddWidgetModal('showAddLinkWidgetModal')
        "
        @hide-modal="actions.hideAddLinkWidgetModal()"
      ></add-brand-link-widget-modal>

      <add-brand-funding-widget-modal
        v-if="state.showAddFundingWidgetModal"
        @save-complete="actions.hideAddFundingWidgetModal()"
        @open-widget-list-modal="
          actions.openAddWidgetModal('showAddFundingWidgetModal')
        "
        @hide-modal="actions.hideAddFundingWidgetModal()"
      ></add-brand-funding-widget-modal>

      <add-brand-banner-widget-modal
        v-if="state.showAddBannerWidgetModal"
        @save-complete="actions.hideAddBannerWidgetModal()"
        @open-widget-list-modal="
          actions.openAddWidgetModal('showAddBannerWidgetModal')
        "
        @hide-modal="actions.hideAddBannerWidgetModal()"
      ></add-brand-banner-widget-modal>

      <add-brand-poll-widget-modal
        v-if="state.showAddPollWidgetModal"
        :polls="state.polls"
        @save-complete="actions.hideAddPollWidgetModal()"
        @open-widget-list-modal="
          actions.openAddWidgetModal('showAddPollWidgetModal')
        "
        @hide-modal="actions.hideAddPollWidgetModal()"
      >
      </add-brand-poll-widget-modal>
      <add-brand-embed-widget-modal
        v-if="state.showAddEmbedWidgetModal"
        @save-complete="actions.hideAddEmbedWidgetModal()"
        @open-widget-list-modal="
          actions.openAddWidgetModal('showAddEmbedWidgetModal')
        "
        @hide-modal="actions.hideAddEmbedWidgetModal()"
      >
      </add-brand-embed-widget-modal>

      <add-widget-modal
        v-if="state.showAddWidgetModal"
        :space-widget-types="state.spaceWidgetTypes"
        @create-widget="
          (type) => {
            actions.createWidget(type);
          }
        "
        @hide-modal="actions.hideAddWidgetModal()"
      ></add-widget-modal>
      <warning-modal
        v-if="state.showDeleteWidgetModal"
        warning-title="삭제 하시겠습니까?"
        warning-text="위젯 삭제 후 다시 추가할 수 있습니다."
        confirm-text="삭제"
        @hideModal="actions.closeDeleteWidgetModal()"
        @confirm="actions.deleteWidget()"
      ></warning-modal>
    </teleport>
  </div>
</template>

<script>
import CardBasic from "@/components/console/cards/CardBasic.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import swal from "@/helper/swal";
import { computed, onMounted, reactive, watchEffect } from "vue";
import { useStore } from "vuex";
import ApiService from "@/api";
import AddBrandLinkWidgetModal from "@/pages/console/EntBrand/AddBrandLinkWidgetModal/AddBrandLinkWidgetModal.vue";
import AddBrandFundingWidgetModal from "@/pages/console/EntBrand/AddBrandFundingWidgetModal/AddBrandFundingWidgetModal.vue";
import PlusIcon from "@/components/console/icons/PlusIcon.vue";
import WidgetItem from "@/pages/console/EntBrand/WidgetItem/WidgetItem.vue";
import draggable from "vuedraggable";
import apiConsole from "@/api/index";
import { cloneDeep } from "lodash";
import AddWidgetModal from "@/pages/console/EntBrand/AddWidgetModal/AddWidgetModal.vue";
import WarningModal from "@/components/console/modals/WarningModal.vue";
import AddBrandBannerWidgetModal from "@/pages/console/EntBrand/AddBrandBannerWidgetModal/AddBrandBannerWidgetModal.vue";
import AddBrandPollWidgetModal from "@/pages/console/EntBrand/AddBrandPollWidgetModal/AddBrandPollWidgetModal.vue";
import AddBrandEmbedWidgetModal from "@/pages/console/EntBrand/AddBrandEmbedWidgetModal/AddBrandEmbedWidgetModal.vue";

export default {
  name: "EntBrandWidgetSetting",
  components: {
    AddBrandEmbedWidgetModal,
    AddBrandPollWidgetModal,
    AddBrandBannerWidgetModal,
    WarningModal,
    AddWidgetModal,
    draggable,
    WidgetItem,
    PlusIcon,
    AddBrandFundingWidgetModal,
    AddBrandLinkWidgetModal,
    ButtonBasic,
    CardBasic,
  },
  props: {
    spaceWidgets: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  emits: ["reloadIframe", "openAddWidgetModal"],
  setup(props, { emit }) {
    const store = useStore();

    const state = reactive({
      spaceWidgetTypes: computed(() => {
        return store.getters["space/spaceWidgetsType"];
      }),
      showAddLinkWidgetModal: false,
      showAddFundingWidgetModal: false,
      showAddPollWidgetModal: false,
      showAddEmbedWidgetModal: false,
      widget: null,
      widgets: [],
      showAddWidgetModal: false,
      showDeleteWidgetModal: false,
      isDeletingWidgetResourceId: null,
      polls: [],
    });

    watchEffect(() => {
      state.widgets = cloneDeep(props.spaceWidgets);
    });

    // todo 스토어로 api 구성하기
    const getAvailablePolls = async () => {
      try {
        const res = await ApiService.getSpacesWidgetPolls();
        if (res.data.success) {
          return res.data.data;
        }
      } catch (e) {
        console.log(e);
        return false;
      }
    };

    const actions = {
      showGuideAlert: () => {
        swal.messageAlert("모바일 기기에서만 가능 합니다.");
      },
      createWidget: async (type) => {
        state.showAddWidgetModal = false;
        if (type === 1) {
          state.showAddLinkWidgetModal = true;
        } else if (type === 9) {
          state.showAddFundingWidgetModal = true;
        } else if (type === 41) {
          state.showAddBannerWidgetModal = true;
        } else if (type === 43) {
          let polls = await getAvailablePolls();
          let isPolls = polls.length > 0;
          if (isPolls) {
            state.polls = polls;
            state.showAddPollWidgetModal = true;
          } else {
            state.showAddWidgetModal = true;
            swal.errorToast("연결 가능한 투표가 없습니다.");
          }
        } else if (type === 49) {
          state.showAddEmbedWidgetModal = true;
        } else {
          let formData = new FormData();
          formData.append("type", type);
          store
            .dispatch("space/postSpaceWidgetV2", { data: formData })
            .then(() => {
              emit("reloadIframe");
              swal.createCompleteToast();
            });
        }
      },
      hideAddLinkWidgetModal: () => {
        state.showAddLinkWidgetModal = false;
      },
      hideAddBannerWidgetModal: () => {
        state.showAddBannerWidgetModal = false;
      },
      hideAddFundingWidgetModal: () => {
        state.showAddFundingWidgetModal = false;
      },
      hideAddPollWidgetModal: () => {
        state.showAddPollWidgetModal = false;
      },
      hideAddEmbedWidgetModal: () => {
        state.showAddEmbedWidgetModal = false;
      },
      showEditLinkWidgetModal: async (data) => {
        let widgetResourceId = data.resourceId;
        await ApiService.getSpaceWidget(widgetResourceId).then((res) => {
          if (res.data.success) {
            state.widget = res.data.data;
            state.showEditLinkWidgetModal = true;
          }
        });
      },
      hideEditLinkWidgetModal: () => {
        state.widget = null;
        state.showEditLinkWidgetModal = false;
      },
      openAddWidgetModal: (caller) => {
        state[`${caller}`] = false;
        state.showAddWidgetModal = true;
      },
      hideAddWidgetModal: () => {
        state.showAddWidgetModal = false;
      },
      sortWidgets: (widgets) => {
        let widgetsResourceIds = [];
        widgets.forEach((widget) => {
          widgetsResourceIds.push(widget.resourceId);
        });

        apiConsole
          .postSpaceWidgetResort({ resourceIds: widgetsResourceIds })
          .then(() => {
            emit("reloadIframe");
            swal.editCompleteToast();
          });
      },
      updateIsPublished: (isPublished, resourceId) => {
        let payload = {
          data: {
            isPublished,
          },
          widgetResourceId: resourceId,
        };
        store.dispatch("space/putSpaceWidgetV2", payload).then(() => {
          emit("reloadIframe");
          swal.editCompleteToast();
        });
      },
      openDeleteWidgetModal: (resourceId) => {
        state.showDeleteWidgetModal = true;
        state.isDeletingWidgetResourceId = resourceId;
      },
      closeDeleteWidgetModal: () => {
        state.showDeleteWidgetModal = false;
        state.isDeletingWidgetResourceId = null;
      },
      deleteWidget: () => {
        store
          .dispatch(
            "space/deleteSpaceWidgetV2",
            state.isDeletingWidgetResourceId
          )
          .then(() => {
            state.showDeleteWidgetModal = false;
            state.isDeletingWidgetResourceId = null;
            emit("reloadIframe");
            swal.deleteCompleteToast();
          });
      },
      updateWidget: (widgetForm, resourceId) => {
        let payload = {
          data: {
            type: widgetForm.type,
            title: widgetForm.title,
            url: widgetForm.url,
            featuredImage: widgetForm.featuredImage,
            isPublished: widgetForm.isPublished,
          },
          widgetResourceId: resourceId,
        };
        store.dispatch("space/putSpaceWidgetV2", payload).then(() => {
          emit("reloadIframe");
          swal.editCompleteToast();
        });
      },
      updateUpcomingWidget: (upcomingWidgetForm, resourceId) => {
        let payload = {
          data: {
            type: upcomingWidgetForm.type,
            title: upcomingWidgetForm.title,
            description: upcomingWidgetForm.description,
            isPublished: upcomingWidgetForm.isPublished,
          },
          widgetResourceId: resourceId,
        };
        store.dispatch("space/putSpaceWidgetV2", payload).then(() => {
          emit("reloadIframe");
          swal.editCompleteToast();
        });
      },
      updateBannerWidget: (bannerWidgetForm, resourceId) => {
        let payload = {
          data: {
            type: bannerWidgetForm.type,
            title: bannerWidgetForm.title,
            description: bannerWidgetForm.description,
            url: bannerWidgetForm.url,
            featuredImage: bannerWidgetForm.featuredImage,
            mFeaturedImage: bannerWidgetForm.mFeaturedImage,
            isPublished: bannerWidgetForm.isPublished,
          },
          widgetResourceId: resourceId,
        };
        store.dispatch("space/putSpaceWidgetV2", payload).then(() => {
          emit("reloadIframe");
          swal.editCompleteToast();
        });
      },
      updatePollWidget: (pollWidgetForm, resourceId) => {
        let payload = {
          data: {
            type: pollWidgetForm.type,
            resourceId: pollWidgetForm.resourceId,
            isPublished: pollWidgetForm.isPublished,
          },
          widgetResourceId: resourceId,
        };
        store.dispatch("space/putSpaceWidgetV2", payload).then(() => {
          emit("reloadIframe");
          swal.editCompleteToast();
        });
      },
      updateEmbedWidget: (embedWidgetForm, resourceId) => {
        let payload = {
          data: {
            type: embedWidgetForm.type,
            title: embedWidgetForm.title,
            description: embedWidgetForm.description,
            // url: embedWidgetForm.url,
            isPublished: embedWidgetForm.isPublished,
          },
          widgetResourceId: resourceId,
        };
        console.log(embedWidgetForm);
        store.dispatch("space/putSpaceWidgetV2", payload).then(() => {
          emit("reloadIframe");
          swal.editCompleteToast();
        });
      },
    };

    return {
      state,
      actions,
    };
  },
};
</script>

<style src="./style.css" scoped></style>
