<template>
  <div class="input-file">
    <div class="input-file-wrapper">
      <div
        v-if="type === 'video' && src"
        class="featured-video"
        :style="`width: ${width}px; height: ${height}px`"
      >
        <video :src="src"></video>
      </div>
      <div
        v-if="type === 'img' && src"
        class="featured-img"
        :style="`width: ${width}px; height: ${height}px`"
      >
        <img :src="src" alt="open hour img" />
      </div>
      <div
        v-if="src === ''"
        class="bg-gray-studio"
        :style="`width: ${width}px; height: ${height}px`"
      >
        <image-icon
          :fill="true"
          class="img-icon"
          width="24"
          height="24"
          fill-color="#818287"
          icon-color="#818287"
        ></image-icon>
      </div>
      <div class="featured-file-overlay"></div>
      <div class="btn-wrapper">
        <button-basic
          shape="round"
          bg-color="#e6eaef"
          :padding="buttonPadding"
          @action="actions.openFileStack()"
        >
          <template #icon>
            <edit-icon width="16" height="16" fill-color="#0D0D10"></edit-icon>
          </template>
        </button-basic>
        <button-basic
          v-if="deleteImage"
          shape="round"
          bg-color="#e6eaef"
          :padding="buttonPadding"
          @action="actions.deleteImage()"
        >
          <template #icon>
            <delete-icon
              width="16"
              height="16"
              fill-color="#0D0D10"
            ></delete-icon>
          </template>
        </button-basic>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive } from "vue";
import ImageIcon from "@/components/console/icons/ImageIcon";
import EditIcon from "@/components/console/icons/EditIcon";
import DeleteIcon from "@/components/console/icons/DeleteIcon.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";

export default {
  name: "EntBrandInputFile",
  components: { DeleteIcon, ImageIcon, EditIcon, ButtonBasic },
  props: {
    accepts: {
      type: Array,
      default: () => {
        return [];
      },
    },
    type: {
      type: String,
      default: "img",
      validator(value) {
        return ["video", "img"].includes(value);
      },
    },
    src: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
    },
    height: {
      type: Number,
    },
    deleteImage: {
      type: Boolean,
      default: false,
    },
    buttonPadding: {
      type: String,
      default: "",
    },
  },
  emits: ["openFIleSelector", "deleteImage"],
  setup(props, { emit }) {
    const state = reactive({
      acceptString: computed(() => {
        return props.accepts.reduce((result, current) => {
          if (result) {
            return `${result}, ${current}`;
          } else {
            return current;
          }
        }, "");
      }),
    });

    const actions = {
      openFileStack: () => {
        emit("openFIleSelector");
      },
      deleteImage: () => {
        emit("deleteImage");
      },
    };

    return { state, actions };
  },
};
</script>

<style scoped>
.input-file p {
  margin-top: 8px;
  margin-bottom: 0;
}

/*////*/
.input-file-wrapper {
  position: relative;
  width: fit-content;
  height: fit-content;
  overflow: hidden;
  border: 1px solid #e6eaef;
  border-radius: 8px;
}

.featured-file-overlay {
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  border-radius: 9px;
  opacity: 0;
  position: absolute;
  width: 120%;
  height: 120%;
  top: -10%;
  left: -10%;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.4);
}

.input-file-wrapper:hover .featured-file-overlay {
  opacity: 1;
}

.featured-video,
.featured-img {
  width: fit-content;
  height: fit-content;
}

.input-file-wrapper video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.input-file-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.input-file-wrapper .img-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.input-file-wrapper .btn-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.2s;
  -webkit-transition: opacity 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: 100%;
}

.input-file-wrapper:hover .btn-wrapper {
  opacity: 1;
}

.input-file-wrapper:hover .btn-wrapper .edit-icon {
  padding: 12px;
  background-color: rgb(230, 234, 239);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.input-file-wrapper .btn-wrapper button {
  width: fit-content;
}
</style>
