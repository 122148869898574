<template>
  <div class="page-container-new">
    <page-loading v-if="state.pageLoading"></page-loading>
    <div v-if="!state.pageLoading">
      <page-header
        title="브랜드 홈 설정"
        description="퍼스널 브랜딩을 위한 나만의 홈페이지를 관리하세요."
        guide-link="https://bigc-im.notion.site/200-79af9550dccc473a97a1452c8d9b9444"
      >
      </page-header>

      <div class="container-body">
        <div class="tab-section">
          <button-tab
            text="위젯"
            :active="state.activeBrandWidget"
            @action="actions.changeRoute('widgets')"
          ></button-tab>

          <button-tab
            text="프로필"
            :active="state.activeBrandProfile"
            @action="actions.changeRoute('profile')"
          ></button-tab>

          <button-tab
            text="디자인"
            :active="state.activeBrandDesign"
            @action="actions.changeRoute('design')"
          ></button-tab>

          <button-tab
            text="브랜드 메뉴"
            :active="state.activeBrandNavigations"
            @action="actions.changeRoute('navigations')"
          ></button-tab>

          <button-tab
            text="인사이트"
            :active="state.activeBrandInsight"
            @action="actions.changeRoute('insight')"
          ></button-tab>
        </div>

        <div class="tab-page">
          <div>
            <ent-brand-widget-setting
              v-if="state.activeBrandWidget"
              :space-widgets="state.spaceWidgets"
              @reload-iframe="actions.reloadIframe()"
            ></ent-brand-widget-setting>
            <ent-brand-profile
              v-if="state.activeBrandProfile"
              :user="state.user"
              :space-channels="state.spaceChannels"
              :space="state.space"
              @reload-iframe="actions.reloadIframe()"
            ></ent-brand-profile>
            <ent-brand-design-setting
              v-if="state.activeBrandDesign"
              :user="state.user"
              :space="state.space"
              @delete-image="
                (type) => {
                  actions.deleteBrandHomeImage(type);
                }
              "
              @reload-iframe="actions.reloadIframe()"
            ></ent-brand-design-setting>
            <ent-brand-navigations-setting
              v-if="state.activeBrandNavigations"
              :space="state.space"
              @reload-iframe="actions.reloadIframe()"
            ></ent-brand-navigations-setting>
            <ent-brand-insight-setting
              v-if="state.activeBrandInsight"
            ></ent-brand-insight-setting>
          </div>

          <div class="iframe">
            <!--            :class="{-->
            <!--            'brand-iframe-none': state.activeBrandInsight,-->
            <!--            }"-->
            <div class="header text-gray-reverse sub-text-s2">
              <span class="sub-text-s2 text-gray-second label"
                >브랜드 홈 주소</span
              >
              <h5
                class="text-gray-reverse address"
                @click="actions.moveToBrandHome()"
              >
                {{ state.spaceUrl.slice(8) }}
              </h5>
              <span
                class="sub-text-s2 text-gray-reverse copy-btn"
                @click="actions.copySpaceUrl()"
              >
                주소 복사
              </span>
            </div>
            <iframe
              id="ent-brand-iframe"
              class="body"
              :src="state.spaceUrl"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeMount, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { isMobile } from "mobile-device-detect";
import helper from "@/helper";
import swal from "@/helper/swal";
import PageHeader from "../../../components/console/headers/PageHeader";
import PageLoading from "../../../components/console/loadings/PageLoading";
import EntBrandInsightSetting from "@/pages/console/EntBrand/EntBrandInsightSetting/EntBrandInsightSetting.vue";
import EntBrandDesignSetting from "./EntBrandDesignSetting/EntBrandDesignSetting.vue";
import EntBrandWidgetSetting from "./EntBrandWidgetSetting/EntBrandWidgetSetting.vue";
import ButtonTab from "@/components/console/buttons/ButtonTab.vue";
import EntBrandProfile from "@/pages/console/EntBrand/EntBrandProfile/EntBrandProfile.vue";
import EntBrandNavigationsSetting from "@/pages/console/EntBrand/EntBrandNavigationsSetting/EntBrandNavigationsSetting.vue";

export default {
  name: "EntBrand",
  components: {
    EntBrandNavigationsSetting,
    EntBrandProfile,
    ButtonTab,
    EntBrandWidgetSetting,
    PageLoading,
    PageHeader,
    EntBrandInsightSetting,
    EntBrandDesignSetting,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const state = reactive({
      user: computed(() => {
        return store.getters["auth/user"];
      }),
      spaceUrl: computed(() => {
        return `${process.env.VUE_APP_ENTER_URL}/${state.user.aliasName}/`;
      }),
      space: computed(() => {
        return store.getters["space/space"];
      }),
      spaceWidgets: computed(() => {
        return store.getters["space/spaceWidgets"];
      }),
      spaceChannels: computed(() => {
        return store.getters["space/spaceChannels"];
      }),
      activeBrandWidget: computed(() => {
        return route.name.includes("console.brand.widgets");
      }),
      activeBrandProfile: computed(() => {
        return route.name.includes("console.brand.profile");
      }),
      activeBrandDesign: computed(() => {
        return route.name.includes("console.brand.design");
      }),
      activeBrandNavigations: computed(() => {
        return route.name.includes("console.brand.navigations");
      }),
      activeBrandInsight: computed(() => {
        return route.name.includes("console.brand.insight");
      }),
      isAdmin: computed(() => {
        return (
          store.getters["auth/user"].isAdmin ||
          store.getters["auth/user"].isStaff
        );
      }),
      pageLoading: true,
      isMobile: isMobile, //디바이스가 모바일 또는 태블릿의 경우 true 반환
    });

    onBeforeMount(() => {
      let completeGetSpace = false;
      let completeGetWidgets = false;

      // TODO 디자인 패턴 -> 옵져버 패턴 개선
      store.dispatch("space/getSpace").then(() => {
        completeGetSpace = true;
        if (completeGetSpace && completeGetWidgets) {
          state.pageLoading = false;
        }
      });

      store.dispatch("space/getSpaceWidgetType").then(() => {
        completeGetWidgets = true;
        if (completeGetSpace && completeGetWidgets) {
          state.pageLoading = false;
        }
      });
    });

    const actions = {
      changeRoute: (tabName) => {
        router.push({
          name: `console.brand.${tabName}`,
        });
      },
      moveToBrandHome: () => {
        if (state.isMobile) {
          window.location.href = state.spaceUrl;
        } else {
          window.open(state.spaceUrl, "_blank");
        }
      },
      copySpaceUrl: () => {
        helper.copyText(state.spaceUrl);
        swal.createCompleteToast("주소가 복사되었습니다.");
      },
      reloadIframe: () => {
        document.getElementById("ent-brand-iframe").src =
          document.getElementById("ent-brand-iframe").src;
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>
