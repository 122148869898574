<template>
  <base-modal
    title="링크 추가"
    sub-title="브랜드 홈에 방문한 분들에게 공유하고 싶은 페이지를 추가하세요."
    @closeModal="state.loading ? '' : $emit('hideModal')"
  >
    <template #modalBody>
      <input-basic
        v-if="!state.isUrlForm"
        class="share-link__input"
        label="공유하고 싶은 페이지 주소"
        placeholder="공유할 URL을 입력해 주세요."
        :error-message="state.errorMessage"
        :required="true"
        :default-value="state.widgetForm.url"
        @updateData="
          (value) => {
            actions.updateLinkUrl(value);
          }
        "
      ></input-basic>

      <div v-if="state.isUrlForm" class="prev-area">
        <div class="thumb-title sub-text-s2">썸네일 이미지</div>
        <div class="thumbnail">
          <div
            class="thumb-img"
            :style="`background-image: url(${state.widgetForm.featuredImage})`"
          ></div>

          <div class="prev-control">
            <button-basic
              class="edit-btn"
              shape="round"
              bg-color="#E6EAEF"
              padding="8px"
              @action="actions.openFileStack()"
            >
              <template #icon>
                <edit-icon fill-color="#0D0D10"></edit-icon>
              </template>
            </button-basic>

            <button-basic
              class="delete-btn"
              shape="round"
              bg-color="#E6EAEF"
              padding="8px"
              @action="actions.deleteImage()"
            >
              <template #icon>
                <delete-icon fill-color="#0D0D10"></delete-icon>
              </template>
            </button-basic>
          </div>
        </div>

        <input-basic
          label="제목"
          placeholder="링크의 제목을 입력해 주세요."
          :required="true"
          :default-value="state.widgetForm.title"
          @updateData="
            (value) => {
              actions.updateLinkTitle(value);
            }
          "
        ></input-basic>
      </div>

      <div class="btn-wrapper">
        <button-basic
          class="cancel-btn"
          bg-color="#ECF1F4"
          color="#0D0D10"
          text="뒤로"
          :disabled="state.loading"
          @action="
            state.isUrlForm
              ? actions.changeInputForm()
              : actions.openListModal()
          "
        ></button-basic>

        <button-basic
          class="save-btn"
          :text="state.isUrlForm ? '추가' : '다음'"
          :disabled="
            state.loading || (state.isUrlForm && !state.isTitle) || !state.isUrl
          "
          :is-loading="state.loading"
          loading-icon-width="20"
          loading-icon-height="20"
          loading-iconview-box="0 0 24 24"
          @action="
            state.isUrlForm
              ? actions.createWidget()
              : actions.getSpaceOpenGraph()
          "
        ></button-basic>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { computed, getCurrentInstance, reactive, ref } from "vue";
import { useStore } from "vuex";
import swal from "@/helper/swal";
import InputBasic from "@/components/console/inputs/InputBasic";
import ApiService from "@/api";
import BaseModal from "@/components/common/BaseModal.vue";
import FileStackService from "@/services/FileStackService";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import EditIcon from "@/components/console/icons/EditIcon.vue";
import DeleteIcon from "@/components/console/icons/DeleteIcon.vue";

export default {
  name: "AddBrandLinkWidgetModal",
  components: {
    DeleteIcon,
    EditIcon,
    ButtonBasic,
    InputBasic,
    BaseModal,
  },
  emits: ["hideModal", "saveComplete", "openWidgetListModal"],
  setup(props, { emit }) {
    const baseModal = ref(null);
    const contentDialog = ref(null);
    const contentModal = ref(null);
    const store = useStore();
    const { proxy } = getCurrentInstance();
    const featuredImageAccepts = ["jpg", "jpeg", "png"];

    const state = reactive({
      widgetForm: {
        type: 1,
        url: "",
        title: "",
        featuredImage: "",
      },
      loading: false,
      errorMessage: "",
      isTitle: computed(() => {
        return state.widgetForm.title.trim().length > 0;
      }),
      isUrl: computed(() => {
        return state.widgetForm.url.trim().length > 0;
      }),
      isUrlForm: false,
    });

    const actions = {
      openFileStack: () => {
        const mimeTypes = featuredImageAccepts.map((item) => {
          return proxy.$const.mimeTypes[item].value;
        });
        const fileStack = new FileStackService();
        fileStack.options.transformations.crop.aspectRatio = 1 / 1;
        fileStack.options.accept = mimeTypes;
        fileStack.options.onFileUploadFinished = async (fileMetaData) => {
          state.widgetForm.featuredImage = fileMetaData.url;
          swal.successToast("변경되었습니다.");
        };

        fileStack.open(fileStack.options);
      },
      deleteImage: () => {
        state.widgetForm.featuredImage = proxy.$const.blankImage;
      },
      updateLinkUrl: (value) => {
        if (state.errorMessage) {
          state.errorMessage = "";
        }
        state.widgetForm.url = value;
      },
      updateLinkTitle: (value) => {
        state.widgetForm.title = value;
      },
      getSpaceOpenGraph: async () => {
        state.loading = true;
        let payload = { url: state.widgetForm.url };
        await ApiService.getSpaceOpenGraph(payload).then((res) => {
          if (res.data.success) {
            state.widgetForm.title = res.data.data.title
              ? res.data.data.title
              : "";
            state.widgetForm.featuredImage = res.data.data["featured_image"]
              ? res.data.data["featured_image"]
              : proxy.$const.blankImage;
            state.isUrlForm = true;
            state.loading = false;
          } else {
            state.errorMessage = "잘못된 URL입니다.";
            state.loading = false;
          }
        });
      },
      createWidget: () => {
        state.loading = true;
        let formData = new FormData();
        formData.append("type", state.widgetForm.type);
        formData.append("url", state.widgetForm.url);
        formData.append("title", state.widgetForm.title);
        if (state.widgetForm.featuredImage !== proxy.$const.blankImage) {
          formData.append("featuredImage", state.widgetForm.featuredImage);
        }

        store.dispatch("space/postSpaceWidget", { data: formData }).then(() => {
          swal.createCompleteToast();
          state.loading = false;
          emit("saveComplete");
        });
      },
      changeInputForm: () => {
        state.isUrlForm = false;
      },
      closeModal: () => {
        if (!state.loading) {
          emit("hideModal");
        }
      },
      openListModal: () => {
        emit("openWidgetListModal");
      },
    };

    return {
      baseModal,
      contentModal,
      contentDialog,
      state,
      actions,
    };
  },
};
</script>

<style src="./style.css" scoped></style>
