<template>
  <div>
    <card-basic class="mobile-guide__card">
      <h4>프로필 레이아웃 설정</h4>
      <p class="sub-text-s2 text-gray-second">
        모바일에서 레이아웃을 설정해보세요.
      </p>

      <p class="b-text-1">
        확인 버튼을 누르시면 모바일 화면으로 전환하여 레이아웃을 설정할 수
        있어요. 설정을 완료하시면 관리화면 상단에 완료 버튼을 통해 다시 스튜디오
        화면으로 전환할 수 있어요.
      </p>

      <button-basic
        text="확인"
        @action="actions.showGuideAlert()"
      ></button-basic>
    </card-basic>

    <!-- 브랜드홈 레이아웃 설정 -->
    <h4>레이아웃 설정</h4>
    <ul class="layout-list">
      <li
        v-for="(item, index) in brandHomeLayout"
        :key="index"
        class="layout-item"
      >
        <div class="header">
          <h4 class="sub-text-s1">{{ item.title }}</h4>
          <p class="sub-text-s2 text-gray-second" v-html="item.description"></p>
        </div>
        <div
          class="body"
          :class="{
            active: state.spaceHeaderType === item.type,
          }"
        >
          <div
            v-if="state[`isSavingHeaderType${item.type}`]"
            class="loading-image"
          >
            <loading-spinner-icon
              class="loading-spinner"
              :width="24"
              :height="24"
              view-box="0 0 24 24"
              icon-color="none"
              clip-path="url(#clip0_19777_153569)"
            ></loading-spinner-icon>
          </div>
          <img :src="item.backgroundImage" />

          <div class="hovered" @click="actions.changeProfileLayout(item.type)">
            <button-basic class="plus-btn" bg-color="#818287" shape="round">
              <template #icon>
                <plus-icon fill-color="#ffffff"></plus-icon>
              </template>
            </button-basic>
          </div>

          <div class="selected">
            <div class="check-icon-bg">
              <check-icon fill-color="#ffffff"></check-icon>
            </div>
          </div>
        </div>
      </li>
    </ul>

    <div class="input-image">
      <input-image-new
        :title="`${
          state.spaceHeaderType === spaceHeaderType.D
            ? '모바일 커버 이미지 업로드(동영상 미노출 시 대체용)'
            : '모바일 커버 업로드'
        }`"
        width=""
        height=""
        size-comment="* 권장 사이즈 : 390px * 470px 이상"
        :accepts="imageAccepts"
        :src="state.mCoverImage"
        :is-saving="state.isSavingMCoverImage"
        @open-file-selector="actions.openFileStack('mCover')"
        @delete-image="actions.deleteUserProfileImage('mCover')"
      ></input-image-new>
    </div>
    <div v-show="state.isMCoverVideo" class="input-image">
      <input-image-new
        title="모바일 커버 동영상 업로드"
        width=""
        height=""
        size-comment="* 권장 영상 비율 : 9:16"
        type="video"
        :src="state.mCoverVideo"
        :accepts="videoAccepts"
        :is-file-stack-availability="false"
        :is-saving="state.isSavingMCoverVideo"
        @file-change="(file) => actions.updateFile(file, 'mCover')"
        @delete-image="actions.deleteFile('mCover')"
      ></input-image-new>
    </div>
    <div v-show="state.isMLogoImage" class="input-image">
      <input-image-new
        title="(선택사항) 모바일 로고 업로드"
        width=""
        height=""
        size-comment="* 권장 사이즈 : 145 px * 54 px 이상"
        :accepts="imageAccepts"
        :src="state.mLogoImage"
        :is-saving="state.isSavingMLogoImage"
        @open-file-selector="actions.openFileStack('mLogo')"
        @delete-image="actions.deleteUserProfileImage('mLogo')"
      ></input-image-new>
    </div>

    <div v-show="state.isCoverImage" class="input-image">
      <input-image-new
        title="PC 커버 업로드"
        width=""
        height=""
        size-comment="* 권장 사이즈 : 1920 px * 500 px 이상"
        :accepts="imageAccepts"
        :src="state.coverImage"
        :is-saving="state.isSavingCoverImage"
        @open-file-selector="actions.openFileStack('cover')"
        @delete-image="actions.deleteUserProfileImage('cover')"
      ></input-image-new>
    </div>
    <div v-show="state.isCoverVideo" class="input-image">
      <input-image-new
        title="PC 커버 동영상 업로드"
        width=""
        height=""
        type="video"
        size-comment="* 권장 이미지 사이즈 : 16:9"
        :is-file-stack-availability="false"
        :accepts="videoAccepts"
        :src="state.coverVideo"
        :is-saving="state.isSavingCoverVideo"
        @file-change="(file) => actions.updateFile(file, 'cover')"
        @delete-image="actions.deleteFile('cover')"
      ></input-image-new>
    </div>
    <div v-show="state.isLogoImage" class="input-image">
      <input-image-new
        title="(선택사항) PC 로고 업로드"
        width=""
        height=""
        size-comment="* 권장 사이즈 : 145 px * 54 px 이상"
        :accepts="imageAccepts"
        :src="state.logoImage"
        @open-file-selector="actions.openFileStack('logo')"
        @delete-image="actions.deleteUserProfileImage('logo')"
      ></input-image-new>
    </div>
    <card-basic
      v-show="state.spaceHeaderType === spaceHeaderType.D"
      class="d-type-form"
    >
      <div>
        <h4>커버 타이틀</h4>
        <input-basic-new
          class="input-item"
          label="커버 영역에 추가로 노출될 타이틀 텍스트를 입력해주세요"
          placeholder="타이틀 텍스트를 입력해주세요"
          :default-value="state.informationForm.title"
          :max-length="state.isAdmin ? '' : 11"
          :caption="`${
            state.informationForm.title?.length
              ? state.informationForm.title?.length
              : 0
          }/11`"
          @update-data="(value) => actions.updateInformationTitle(value)"
        ></input-basic-new>
      </div>
      <div>
        <h4>커버 서브 타이틀</h4>
        <input-text-box
          label="커버 영역에 노출될 서브 타이틀을 입력해주세요"
          placeholder="서브 타이틀을 입력해주세요"
          :model-value="state.informationForm.description"
          :max-length="state.isAdmin ? '' : 46"
          :caption="`${
            state.informationForm.description?.length
              ? state.informationForm.description.length
              : 0
          }/46`"
          :rows="3"
          :height-resize="false"
          :border-none="false"
          @update:modelValue="
            (value) => {
              actions.updateInformationDescription(value);
            }
          "
        ></input-text-box>
      </div>
      <div>
        <h4>커버 버튼 라벨명</h4>
        <input-basic-new
          class="input-item"
          label="커버 영역에 노출될 버튼 라벨명을 입력해주세요"
          placeholder="버튼 라벨명을 입력해주세요"
          :default-value="state.informationForm.button"
          :max-length="state.isAdmin ? '' : 18"
          :caption="`${
            state.informationForm.button?.length
              ? state.informationForm.button?.length
              : 0
          }/18`"
          @update-data="(value) => actions.updateInformationButton(value)"
        ></input-basic-new>
      </div>
      <div>
        <h4>커버 버튼 URL</h4>
        <input-basic-new
          class="input-item"
          label="커버 영역에 노출될 버튼에 연결할 URL 정보를 입력해주세요"
          placeholder="URL을 입력해주세요"
          :default-value="state.informationForm.url"
          @update-data="(value) => actions.updateInformationUrl(value)"
        ></input-basic-new>
      </div>

      <button-basic
        class="create-btn"
        text="저장"
        padding="11px 16px"
        :disabled="state.isSavingInformation"
        :is-loading="state.isSavingInformation"
        @action="actions.saveInformation()"
      ></button-basic>
    </card-basic>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, getCurrentInstance, onBeforeMount, reactive } from "vue";
import swal from "@/helper/swal";
import CardBasic from "@/components/console/cards/CardBasic.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import PlusIcon from "@/components/console/icons/PlusIcon.vue";
import CheckIcon from "@/components/console/icons/CheckIcon.vue";
import InputImageNew from "@/components/console/inputs/InputImageNew.vue";
import FileStackService from "@/services/FileStackService";
import ApiService from "@/api";
import InputBasicNew from "@/components/console/inputs/InputBasicNew.vue";
import InputTextBox from "@/components/console/inputs/InputTextBox.vue";
import { SPACE } from "@/store/mutation-types";
import LoadingSpinnerIcon from "@/components/console/icons/LoadingSpinnerIcon.vue";

export default {
  name: "EntBrandDesignSetting",
  components: {
    LoadingSpinnerIcon,
    InputTextBox,
    InputBasicNew,
    InputImageNew,
    CheckIcon,
    PlusIcon,
    ButtonBasic,
    CardBasic,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  emits: ["deleteImage", "reloadIframe"],
  setup(props, { emit }) {
    const store = useStore();
    const { proxy } = getCurrentInstance();

    const imageAccepts = ["jpg", "jpeg", "png"];
    const videoAccepts = ["mp4"];

    const brandHomeLayout = [
      {
        type: 1,
        backgroundImage: "/assets/images/brand/layout_01.jpg",
        title: "Type A :",
        description: "커버 + 프로필 이미지",
      },
      {
        type: 2,
        backgroundImage: "/assets/images/brand/layout_02.jpg",
        title: "Type B :",
        description: "커버 + 로고 이미지",
      },
      {
        type: 3,
        backgroundImage: "/assets/images/brand/layout_03.jpg",
        title: "Type C :",
        description: "커버 동영상 </br>(모바일-이미지)",
      },
      {
        type: 4,
        backgroundImage: "/assets/images/brand/layout_04.jpg",
        title: "Type D :",
        description: "커버 동영상 + 추가정보",
      },
    ];

    const spaceHeaderType = {
      A: 1,
      B: 2,
      C: 3,
      D: 4,
    };

    const state = reactive({
      spaceHeaderType: null,
      // note userProfile의 coverImage, mCoverImage, logoImage, mLogoImage를 업데이트하기위한 데이터임
      coverImage: "",
      logoImage: "",
      mCoverImage: "",
      mLogoImage: "",
      // note space coverImage, mCoverImage를 업데이트하기위한 데이터임
      coverVideo: "",
      mCoverVideo: "",
      isCoverImage: computed(() => {
        return (
          state.spaceHeaderType === spaceHeaderType.A ||
          state.spaceHeaderType === spaceHeaderType.B
        );
      }),
      isLogoImage: computed(() => {
        return (
          state.spaceHeaderType === spaceHeaderType.B ||
          state.spaceHeaderType === spaceHeaderType.C
        );
      }),
      isMLogoImage: computed(() => {
        return (
          state.spaceHeaderType === spaceHeaderType.B ||
          state.spaceHeaderType === spaceHeaderType.C
        );
      }),
      isCoverVideo: computed(() => {
        return (
          state.spaceHeaderType === spaceHeaderType.C ||
          state.spaceHeaderType === spaceHeaderType.D
        );
      }),
      isMCoverVideo: computed(() => {
        return state.spaceHeaderType === spaceHeaderType.D;
      }),
      isSavingInformation: false,
      informationForm: {
        title: "",
        description: "",
        button: "",
        url: "",
      },
      isSavingCoverImage: false,
      isSavingLogoImage: false,
      isSavingMCoverImage: false,
      isSavingMLogoImage: false,
      isSavingCoverVideo: false,
      isSavingMCoverVideo: false,
      isSavingHeaderType1: false,
      isSavingHeaderType2: false,
      isSavingHeaderType3: false,
      isSavingHeaderType4: false,
      isAdmin: computed(() => {
        return (
          store.getters["auth/user"].isAdmin ||
          store.getters["auth/user"].isStaff
        );
      }),
    });

    onBeforeMount(() => {
      state.coverImage = props.user.userProfile.coverImageUrl ?? "";
      state.logoImage = props.user.userProfile.logoImageUrl ?? "";
      state.mCoverImage = props.user.userProfile.mCoverImageUrl ?? "";
      state.mLogoImage = props.user.userProfile.mLogoImageUrl ?? "";
      state.spaceHeaderType = store.getters["space/space"].headerType;
      state.informationForm = {
        ...state.informationForm,
        ...store.getters["space/space"].information,
      };
      state.coverVideo =
        store.getters["space/space"].coverImageUrl ?? proxy.$const.blankImage;
      state.mCoverVideo =
        store.getters["space/space"].mCoverImageUrl ?? proxy.$const.blankImage;
    });

    const updateBrandHomeImage = (data) => {
      let key = Object.entries(data)[0][0];
      let value = Object.entries(data)[0][1];
      let formData = new FormData();
      formData.append("file", value);
      if (key === "mCoverImage") {
        state.isSavingMCoverImage = true;
        ApiService.uploadUserMobileProfileCover(formData).then((res) => {
          state.mCoverImage = res.data.data.userProfile.mCoverImageUrl;
          state.isSavingMCoverImage = false;
          emit("reloadIframe");
          swal.createCompleteToast();
        });
      } else if (key === "mLogoImage") {
        state.isSavingMLogoImage = true;
        ApiService.uploadUserMobileProfileLogo(formData).then((res) => {
          state.mLogoImage = res.data.data.userProfile.mLogoImageUrl;
          state.isSavingMLogoImage = false;
          emit("reloadIframe");
          swal.createCompleteToast();
        });
      } else if (key === "coverImage") {
        state.isSavingCoverImage = true;
        ApiService.uploadUserProfileCover(formData).then((res) => {
          state.coverImage = res.data.data.userProfile.coverImageUrl;
          state.isSavingCoverImage = false;
          emit("reloadIframe");
          swal.createCompleteToast();
        });
      } else {
        state.isSavingLogoImage = true;
        ApiService.uploadUserProfileLogo(formData).then((res) => {
          state.logoImage = res.data.data.userProfile.logoImageUrl;
          state.isSavingLogoImage = false;
          emit("reloadIframe");
          swal.createCompleteToast();
        });
      }
    };

    const postAttachment = async (data) => {
      const formData = new FormData();
      formData.append("file", data);
      try {
        const res = await ApiService.postAttachment(formData);
        if (res.data.success) {
          return res.data.data;
        }
      } catch (e) {
        console.error(e);
        return false;
      }
    };

    const actions = {
      deleteFile: async (type) => {
        type === "cover"
          ? (state.isSavingCoverVideo = true)
          : (state.isSavingMCoverVideo = true);
        await ApiService.deleteSpacesCoverImageUrl(type).then(() => {
          type === "cover"
            ? (state.isSavingCoverVideo = false)
            : (state.isSavingMCoverVideo = false);
          emit("reloadIframe");
          type === "cover" ? (state.coverVideo = "") : (state.mCoverVideo = "");
          swal.deleteCompleteToast();
        });
      },
      deleteUserProfileImage: (type) => {
        state[`${type}Image`] = null;
        if (type === "mCover") {
          state.isSavingMCoverImage = true;
          ApiService.deleteUserMobileProfileCover().then(() => {
            state.isSavingMCoverImage = false;
            emit("reloadIframe");
            swal.deleteCompleteToast();
          });
        } else if (type === "mLogo") {
          state.isSavingMLogoImage = true;
          ApiService.deleteUserMobileProfileLogo().then(() => {
            state.isSavingMLogoImage = false;
            emit("reloadIframe");
            swal.deleteCompleteToast();
          });
        } else if (type === "cover") {
          state.isSavingCoverImage = true;
          ApiService.deleteUserProfileCover().then(() => {
            state.isSavingCoverImage = false;
            emit("reloadIframe");
            swal.deleteCompleteToast();
          });
        } else {
          state.isSavingLogoImage = true;
          ApiService.deleteUserProfileLogo().then(() => {
            state.isSavingLogoImage = false;
            emit("reloadIframe");
            swal.deleteCompleteToast();
          });
        }
      },
      changeProfileLayout: (type) => {
        let payload = {
          headerType: type,
          footerCompany: store.getters["space/space"].footerCompany,
          information: state.informationForm,
        };

        state[`isSavingHeaderType${type}`] = true;

        store.dispatch("space/putSpace", { data: payload }).then(() => {
          emit("reloadIframe");
          state.spaceHeaderType = type;
          state[`isSavingHeaderType${type}`] = false;
          swal.editCompleteToast();
        });
      },
      changeLocalProfileLayout: (type) => {
        state.spaceHeaderType = type;
      },
      showGuideAlert: () => {
        swal.messageAlert("모바일 기기에서만 가능 합니다.");
      },
      openFileStack: async (type) => {
        // note 기획자 요청, 메인 페이지에 걸리는 이미지가 너무 커서 모먼트팀에서 webp로 압축한 이미지를 넣었음, 현재 webp를 사용해도 문제가 없다 판단하에 일단 스튜디오 ui상에서는 webp 지원한다는 메시지는 없지만, 실제 업로드때는 webp 허용되도록 수정.
        let extensionWithTypeAdded = [...imageAccepts, ["webp"]];

        const mimeTypes = extensionWithTypeAdded.map((item) => {
          return proxy.$const.mimeTypes[item].value;
        });

        let fileStack = new FileStackService();

        let aspectRatio = null;
        if (state.isAdmin) {
          fileStack.options.transformations.crop = false;
        } else {
          if (type === "mCover") {
            aspectRatio = 0.8 / 1;
          } else if (type === "mLogo") {
            aspectRatio = 2.7 / 1;
          } else if (type === "cover") {
            aspectRatio = 3.8 / 1;
          } else {
            aspectRatio = 2.7 / 1;
          }

          aspectRatio &&
            (fileStack.options.transformations.crop.aspectRatio = aspectRatio);
        }
        fileStack.options.accept = mimeTypes;
        fileStack.options.onFileUploadFinished = async (fileMetaData) => {
          let data = await postAttachment(fileMetaData.url);
          updateBrandHomeImage({ [`${type}Image`]: data.url });
        };

        fileStack.open(fileStack.options);
      },
      updateInformationTitle: (value) => {
        state.informationForm.title = value;
      },
      updateInformationDescription: (value) => {
        state.informationForm.description = value;
      },
      updateInformationButton: (value) => {
        state.informationForm.button = value;
      },
      updateInformationUrl: (value) => {
        state.informationForm.url = value;
      },
      saveInformation: () => {
        state.isSavingInformation = true;

        let payload = {
          headerType: state.spaceHeaderType,
          footerCompany: store.getters["space/space"].footerCompany,
          information: state.informationForm,
        };

        store.dispatch("space/putSpace", { data: payload }).then(() => {
          emit("reloadIframe");
          // state.spaceHeaderType = type;
          swal.editCompleteToast();
          state.isSavingInformation = false;
        });
      },
      updateFile: async (file, type) => {
        type === "cover"
          ? (state.isSavingCoverVideo = true)
          : (state.isSavingMCoverVideo = true);
        // todo error-handling 코드 개선
        let data = await postAttachment(file);
        if (!data) {
          type === "cover"
            ? (state.isSavingCoverVideo = false)
            : (state.isSavingMCoverVideo = false);
          return;
        }
        let formData = new FormData();
        formData.append("file", data.url);

        // 작업중
        store
          .dispatch("space/postSpacesCoverImageUrl", {
            type: type,
            data: formData,
          })
          .then((data) => {
            state[`${type}Video`] = data[`${type}ImageUrl`];
            type === "cover"
              ? (state.isSavingCoverVideo = false)
              : (state.isSavingMCoverVideo = false);
            emit("reloadIframe");
            swal.editCompleteToast();
          });
      },
    };

    return {
      state,
      actions,
      imageAccepts,
      videoAccepts,
      brandHomeLayout,
      spaceHeaderType,
    };
  },
};
</script>

<style src="./style.css" scoped></style>
